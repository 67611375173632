var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-btn",
    {
      staticClass: "back-button",
      attrs: { icon: "", "data-cy": "back-button", text: "" },
      on: { click: _vm.goBack },
    },
    [
      _c("img", {
        staticClass: "back-icon",
        attrs: { src: "/content/svgs/arrow-back.svg", alt: "Back" },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }