var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    {
      staticClass: "mx-0 mb-8 pl-0 pt-0",
      attrs: {
        id: "stemdoers-list",
        fluid: "",
        "data-cy": "stemdoers-list-container",
      },
    },
    [
      _vm.displaySkeleton
        ? _c("SSkeletonTable", {
            attrs: { "data-cy": "stemdoers-skeleton", headers: _vm.headers },
          })
        : _c("v-data-table", {
            staticClass: "remove-box-shadow stemdoers-table",
            attrs: {
              "data-cy": "stemdoers-list-table",
              headers: _vm.headers,
              items: _vm.stemdoersInPage,
              "hide-default-footer": "",
              "disable-pagination": "",
            },
            scopedSlots: _vm._u([
              {
                key: "item.fullName",
                fn: function ({ item }) {
                  return [
                    _c(
                      "td",
                      {
                        staticClass: "pl-6 ellipsis",
                        attrs: { "data-cy": "stemdoers-list-name-cell" },
                      },
                      [
                        _c("SAvatarStemdoer", {
                          attrs: {
                            "data-cy": "stemdoers-list-avatar",
                            stemdoId: item.stemdoId,
                            size: "21px",
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            staticClass: "pl-1",
                            attrs: { "data-cy": "stemdoers-list-fullname" },
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(item.name + " " + item.surname) +
                                "\n        "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
              {
                key: "item.position",
                fn: function ({ item }) {
                  return [
                    _c(
                      "span",
                      {
                        staticClass: "ellipsis",
                        attrs: { "data-cy": "stemdoers-list-position" },
                      },
                      [
                        _vm._v(
                          "\n        " + _vm._s(item.position) + "\n      "
                        ),
                      ]
                    ),
                  ]
                },
              },
              {
                key: "item.verticalName",
                fn: function ({ item }) {
                  return [
                    _c(
                      "span",
                      { attrs: { "data-cy": "stemdoers-list-vertical" } },
                      [
                        _vm._v(
                          "\n        " +
                            _vm._s(
                              _vm.$t(
                                "stemdoers-filter-list.vertical." +
                                  item.verticals[0].name
                                    .toLowerCase()
                                    .replace(/\s+/g, "")
                              )
                            ) +
                            "\n      "
                        ),
                      ]
                    ),
                  ]
                },
              },
              {
                key: "item.hide",
                fn: function ({ item }) {
                  return [
                    _c(
                      "div",
                      {
                        staticClass: "status-container",
                        attrs: { "data-cy": "stemdoers-status-cell" },
                      },
                      [
                        _c("SLabel", {
                          staticClass: "text-body-3 p-1 pl-3 pr-3",
                          attrs: {
                            text: _vm.getStatusDetails(item.hide).translation,
                            labelStyle: _vm.getStatusDetails(item.hide)
                              .labelStyle,
                            "data-cy": "stemdoers-status-label",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
      _vm._v(" "),
      !_vm.displaySkeleton
        ? _c(
            "div",
            {
              staticClass: "pagination-container",
              attrs: { "data-cy": "stemdoers-pagination" },
            },
            [
              _c("SPagination", {
                attrs: {
                  itemsPerPage: _vm.ITEMS_PER_PAGE,
                  totalItems: _vm.stemdoers.length,
                  onPageChange: _vm.onPageChange,
                  "data-cy": "stemdoers-spagination",
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }