var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("v-autocomplete", {
    staticClass: "custom-v-autocomplete",
    style: _vm.customStyle,
    attrs: {
      "data-cy": "sautocomplete",
      label: _vm.$t(_vm.placeholder),
      items: _vm.items,
      "item-value": "id",
      "item-text": "desc",
      height: _vm.inputHeight,
      "return-object": "",
      disabled: _vm.disabled,
      dense: "",
      flat: "",
      solo: "",
    },
    on: {
      change: _vm.handleChange,
      blur: _vm.handleBlur,
      focus: _vm.handleFocus,
    },
    scopedSlots: _vm._u([
      {
        key: "selection",
        fn: function ({ index }) {
          return [
            !_vm.labelDisplaySelection && index === 0
              ? _c(
                  "span",
                  {
                    staticClass:
                      "pt-1 pb-1 text-body-1 text--text text--lighten-1",
                    attrs: { "data-cy": "sautocomplete-selection-label" },
                  },
                  [_vm._v("\n      " + _vm._s(_vm.labelDisplay) + "\n    ")]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.labelDisplaySelection && index === 0
              ? _c(
                  "span",
                  {
                    staticClass:
                      "pt-1 pb-1 text-body-1-medium text--text text--base",
                    attrs: { "data-cy": "sautocomplete-selection-label" },
                  },
                  [_vm._v("\n      " + _vm._s(_vm.labelDisplay) + "\n    ")]
                )
              : _vm._e(),
          ]
        },
      },
      {
        key: "item",
        fn: function ({ item }) {
          return [
            _c(
              "div",
              {
                class: [
                  "text--text",
                  "text--base",
                  { "selected-item": _vm.isItemSelected(item) },
                  { "text-body-1-bold": _vm.isItemSelected(item) },
                  { "text-body-1": !_vm.isItemSelected(item) },
                ],
                attrs: { "data-cy": "sautocomplete-item" },
              },
              [_vm._v("\n      " + _vm._s(item.desc) + "\n    ")]
            ),
          ]
        },
      },
    ]),
    model: {
      value: _vm.selectedItem,
      callback: function ($$v) {
        _vm.selectedItem = $$v
      },
      expression: "selectedItem",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }