import { computed, defineComponent, inject, onMounted, ref, watch } from 'vue';
import TeamRequestService from '@/sections/admin/entities/team-request/team-request.service';
import TeamRequestsList from './team-requests-list/TeamRequestsList.vue';
import { TeamRequests } from '@/shared/model/team-request.model';
import TeamRequestsFilter from './team-requests-filter/TeamRequestsFilter.vue';
import SelectionMultipleFilter from '@/shared/model/filter/single/selection-multiple-filter.model';
import Filters from '@/shared/model/filter/filters.model';
import TextFilter from '@/shared/model/filter/single/text-filter.model';
import FilterGroupOr from '@/shared/model/filter/group/filter-group-or.model';
import { useStore } from '@/plugins/vuex';

export default defineComponent({
  components: {
    TeamRequestsList,
    TeamRequestsFilter
  },
  setup() {
    const store = useStore();
    const teamRequestService: TeamRequestService = inject('teamRequestService');
    const teamRequests = ref<TeamRequests>(TeamRequests.empty());
    const filteredTeamRequests = ref<TeamRequests>(TeamRequests.empty());
    const isLoading = ref(true);
    const displaySkeleton = computed(() => {
      return isLoading.value || (teamRequests.value.length === 0 && isLoading.value);
    });
    const filters = ref<Filters>(new Filters('AND'));

    onMounted(() => {
      retrieveAllRequestsByManager();
    });

    watch(
      () => filters.value,
      f => {
        filteredTeamRequests.value = f.filter(teamRequests.value) as TeamRequests;
      },
      { deep: true }
    );

    const retrieveAllRequestsByManager = () => {
      isLoading.value = true;
      teamRequestService
        .getAllRequestsByManager()
        .then(res => {
          teamRequests.value = TeamRequests.from(res.data);
          filteredTeamRequests.value = TeamRequests.from(res.data);
          isLoading.value = false;
        })
        .catch(err => {
          console.error(err);
        });
    };

    const handleCompanyChange = companyIds => {
      filters.value.add(new SelectionMultipleFilter('client.company.id', companyIds));
      store.commit('pagesStore/manager/teamRequests/setCompanyFilter', companyIds);
    };

    const handleStatusChange = statusIds => {
      filters.value.add(new SelectionMultipleFilter('status', statusIds));
      store.commit('pagesStore/manager/teamRequests/setStatusFilter', statusIds);
    };

    const handleSearchChange = searchTerm => {
      const groupOr = new FilterGroupOr('searchText');
      groupOr.add(new TextFilter('client.name', searchTerm));
      groupOr.add(new TextFilter('client.surname', searchTerm));
      groupOr.add(new TextFilter('client.company.name', searchTerm));
      groupOr.add(new TextFilter('logSummary.numberOfViews', searchTerm));
      groupOr.add(new TextFilter('initDate', searchTerm));
      groupOr.add(new TextFilter('logSummary.lastView', searchTerm));
      filters.value.add(groupOr);
      store.commit('pagesStore/manager/teamRequests/setSearchFilter', searchTerm);
    };

    return {
      filteredTeamRequests,
      teamRequests,
      displaySkeleton,
      handleCompanyChange,
      handleStatusChange,
      handleSearchChange
    };
  }
});
