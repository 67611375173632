var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    {
      staticClass: "mx-0 pl-0 pt-0 pb-0",
      attrs: { fluid: "", "data-cy": "stemdoers-filter-container" },
    },
    [
      _c(
        "v-card-title",
        { staticClass: "d-flex align-center filter-container" },
        [
          _c(
            "v-row",
            {
              staticClass: "d-flex align-center p-3",
              attrs: { "no-gutters": "" },
            },
            [
              _c(
                "v-col",
                {
                  staticClass: "p-2",
                  attrs: { cols: "2", "data-cy": "filter-vertical-col" },
                },
                [
                  _c("SSelectorMultiple", {
                    attrs: {
                      "data-cy": "filter-vertical-selector",
                      defaultSelectedItemsIds: _vm.storedVerticalsSelected,
                      placeholder: _vm.$t(
                        "stemdoers-filter-list.filter.vertical"
                      ),
                      items: _vm.verticalItems,
                      onChange: _vm.onVerticalChange,
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                {
                  staticClass: "p-2",
                  attrs: { cols: "2", "data-cy": "filter-status-col" },
                },
                [
                  _c("SSelectorMultiple", {
                    attrs: {
                      "data-cy": "filter-status-selector",
                      defaultSelectedItemsIds: _vm.storedStatusSelected,
                      placeholder: _vm.$t(
                        "stemdoers-filter-list.filter.status"
                      ),
                      items: _vm.statusItems,
                      onChange: _vm.onStatusChange,
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                {
                  staticClass: "p-2 pl-2 pr-2",
                  attrs: { cols: "8", "data-cy": "filter-search-col" },
                },
                [
                  _c("SSearch", {
                    attrs: {
                      "data-cy": "filter-search-input",
                      defaultText: _vm.storedSearch,
                      placeholder: _vm.$t(
                        "stemdoers-filter-list.filter.search"
                      ),
                      onChange: _vm.onSearch,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }