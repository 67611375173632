var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    {
      staticClass: "mx-0 top-filter-wrapper",
      attrs: { fluid: "", "data-cy": "top-filter-container" },
    },
    [
      _c(
        "v-row",
        {
          staticClass: "p-0 d-flex justify-content-center align-items-center",
          class: { "p-2": !_vm.isManager },
          attrs: { "data-cy": "top-filter-row" },
        },
        [
          _c(
            "v-col",
            { attrs: { "data-cy": "top-filter-left-col" } },
            [
              _c(
                "v-row",
                {
                  staticClass:
                    "flex-nowrap d-flex justify-content-center align-items-center",
                  attrs: { "data-cy": "top-filter-inner-row" },
                },
                [
                  _vm.isManager
                    ? _c(
                        "v-col",
                        {
                          staticClass: "p-0",
                          class: { "pr-0": _vm.isManager },
                          attrs: {
                            cols: "2",
                            "data-cy": "client-selector-col",
                          },
                        },
                        [
                          _c("ClientSelector", {
                            attrs: {
                              "data-cy": "client-selector",
                              defaultClientId: _vm.defaultClientSelector?.id,
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    {
                      attrs: {
                        cols: _vm.isManager ? 5 : 6,
                        "data-cy": "vertical-autocomplete-col",
                      },
                    },
                    [
                      _c("SAutocomplete", {
                        attrs: {
                          "data-cy": "vertical-autocomplete",
                          placeholder: "explore.filter.placeholder-position",
                          items: _vm.verticalsItems,
                          defaultSelectedItem: _vm.defaultSelectedVertical,
                          filterField: _vm.technologiesField,
                          action: _vm.clickVerticalFilter,
                          inputHeight: 45,
                          labelDisplaySelection: true,
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    {
                      attrs: {
                        cols: _vm.isManager ? 5 : 6,
                        "data-cy": "competency-autocomplete-col",
                      },
                    },
                    [
                      _c("SAutocompleteMultipleRating", {
                        key: _vm.compFilterKey,
                        attrs: {
                          "data-cy": "competency-autocomplete",
                          placeholder: "explore.filter.placeholder-competency",
                          items: _vm.technologyItemsByVertical,
                          filterField: _vm.competenciesField,
                          action: _vm.clickCompFilter,
                          inputHeight: 45,
                          labelDisplaySelection: true,
                          label: "explore.filter.label-competency",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            {
              staticClass: "ml-auto",
              attrs: { cols: "auto", "data-cy": "more-filters-col" },
            },
            [
              _c(
                "div",
                { attrs: { "data-cy": "more-filters-btn-wrapper" } },
                [
                  _c("SButton", {
                    attrs: {
                      "data-cy": "more-filters-btn",
                      btnStyle: "tertiary",
                      iconSrc: _vm.moreFiltersIconSrc,
                      sText: "explore.filter.moreFilters",
                      action: _vm.openMoreFiltersModal,
                    },
                  }),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.totalTags > 0
        ? _c(
            "v-row",
            {
              staticClass: "p-2 filters-tags",
              attrs: { "data-cy": "active-filters-tags-row" },
            },
            [
              _c(
                "v-col",
                {
                  staticClass: "pa-2",
                  attrs: { "data-cy": "active-filters-tags-col" },
                },
                [
                  _c("FilterTagList", {
                    attrs: { "data-cy": "filter-tag-list" },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("SModalCentered", {
        attrs: {
          "data-cy": "more-filters-modal",
          title: "explore.more-filters.modal.title",
          isOpen: _vm.openMoreFilters,
          maxWidth: "700px",
          closeModal: _vm.closeMoreFiltersModal,
          persistent: true,
        },
        scopedSlots: _vm._u([
          {
            key: "body",
            fn: function () {
              return [
                _c("MoreFilters", {
                  attrs: {
                    "data-cy": "more-filters-body",
                    techItems: _vm.verticalsItems,
                    compItems: _vm.technologyItems,
                    softSkillsItems: _vm.softSkillsItems,
                    rates: _vm.rates,
                    clickTechFilter: _vm.clickVerticalFilter,
                  },
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "footer",
            fn: function () {
              return [
                _c("SButton", {
                  attrs: {
                    "data-cy": "more-filters-confirm-btn",
                    btnStyle: "primary",
                    sText: "explore.more-filters.modal.button-confirm",
                    action: _vm.applyFilters,
                  },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }