import FilterSingle from './filter-single.model';

class TextFilter extends FilterSingle {
  filterValue: string;

  constructor(field: string, value: string) {
    super(field, field);
    this.filterValue = value;
  }

  filter(entity: any): boolean {
    if (this.filterValue === '') return true;
    const fieldValue = this.getFieldValueOf(entity);

    if (typeof fieldValue === 'number') {
      return fieldValue.toString().includes(this.filterValue);
    }
    const fieldDateValue = new Date(fieldValue);
    if (!isNaN(fieldDateValue.getTime())) {
      return fieldDateValue.toLocaleDateString('en-GB').includes(this.filterValue);
    }
    if (Array.isArray(fieldValue)) {
      return fieldValue.some(value => value.name.toString().toLowerCase().includes(this.filterValue.toLowerCase()));
    }

    return fieldValue.toLowerCase().includes(this.filterValue.toLowerCase());
  }

  toString(): string {
    return `${this.field}=${this.filterValue}`;
  }
}

export default TextFilter;
