import axios from 'axios';
import localStorageFacade, { StorageKey } from './local-store-facade/LocalStoreFacade';

const baseApiUrl = 'api/import';

export default class ResourceService {
  public findImg(resource: string): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      axios
        .get(baseApiUrl + '/foto?resource=' + resource, {
          headers: {
            Authorization: 'Bearer ' + localStorageFacade.get(StorageKey.AUTH_TOKEN)
          },
          responseType: 'blob'
        })
        .then(res => {
          if (res.data.type.includes('image')) {
            resolve(URL.createObjectURL(res.data));
          } else {
            resolve(res.headers['x-initials']);
          }
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
