import { computed, defineComponent, ref } from 'vue';
import SAvatarStemdoer from '@/components/stemdo-components/s-avatar-stemdoer/SAvatarStemdoer.vue';
import { useI18N } from '@/plugins/i18n';
import SPagination from '@/components/stemdo-components/s-pagination/SPagination.vue';
import SSkeletonTable from '@/components/stemdo-components/s-skeleton-table/SSkeletonTable.vue';
import { LabelStyles } from '@/components/stemdo-components/s-label/sLabel';
import SLabel from '@/components/stemdo-components/s-label/SLabel.vue';

export default defineComponent({
  props: {
    stemdoers: {
      type: Array,
      required: true
    },
    displaySkeleton: {
      type: Boolean,
      default: true
    }
  },
  components: {
    SAvatarStemdoer,
    SPagination,
    SSkeletonTable,
    SLabel
  },
  setup(props) {
    const i18n = useI18N();
    const ITEMS_PER_PAGE = 12;
    const offset = ref(0);
    const stemdoersInPage = computed(() => {
      const start = offset.value * ITEMS_PER_PAGE;
      const end = start + ITEMS_PER_PAGE;
      return props.stemdoers.slice(start, end);
    });

    const headers = computed(() => [
      { text: i18n.t('stemdoers-filter-list.table.name'), value: 'fullName', width: '29%' },
      { text: i18n.t('stemdoers-filter-list.table.vertical'), value: 'verticalName', width: '18%' },
      { text: i18n.t('stemdoers-filter-list.table.title'), value: 'position', width: '25%' },
      { text: i18n.t('stemdoers-filter-list.table.email'), value: 'email', width: '23%' },
      { text: i18n.t('stemdoers-filter-list.table.status'), value: 'hide' }
    ]);

    const getStatusDetails = (status: boolean) => {
      let translation = '';
      let labelStyle = '';
      switch (status) {
        case true:
          translation = i18n.t('stemdoers-filter-list.status.inactive') as string;
          labelStyle = LabelStyles.Senary;
          break;
        case false:
          translation = i18n.t('stemdoers-filter-list.status.active') as string;
          labelStyle = LabelStyles.Tertiary;
          break;
        default:
          break;
      }
      return { translation, labelStyle };
    };

    const onPageChange = (newPage: number) => {
      offset.value = newPage - 1;
    };

    return {
      headers,
      stemdoersInPage,
      ITEMS_PER_PAGE,
      onPageChange,
      getStatusDetails
    };
  }
});
