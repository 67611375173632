var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      (_vm.hasAnyAuthority("ROLE_RRHH") || _vm.hasAnyAuthority("ROLE_ADMIN")) &&
      _vm.authenticated
        ? _c(
            "b-dropdown-item",
            { attrs: { to: "/vertical" } },
            [
              _c("font-awesome-icon", { attrs: { icon: "asterisk" } }),
              _vm._v(" "),
              _c("span", {
                domProps: {
                  textContent: _vm._s(_vm.$t("global.menu.entities.vertical")),
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.hasAnyAuthority("ROLE_ADMIN") && _vm.authenticated
        ? _c(
            "b-dropdown-item",
            { attrs: { to: "/staff" } },
            [
              _c("font-awesome-icon", { attrs: { icon: "asterisk" } }),
              _vm._v(" "),
              _c("span", {
                domProps: {
                  textContent: _vm._s(_vm.$t("global.menu.entities.staff")),
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        { attrs: { to: "/stemdoer" } },
        [
          _c("font-awesome-icon", { attrs: { icon: "asterisk" } }),
          _vm._v(" "),
          _c("span", {
            domProps: {
              textContent: _vm._s(_vm.$t("global.menu.entities.stemdoer")),
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        { attrs: { to: "/technology" } },
        [
          _c("font-awesome-icon", { attrs: { icon: "asterisk" } }),
          _vm._v(" "),
          _c("span", {
            domProps: {
              textContent: _vm._s(_vm.$t("global.menu.entities.technology")),
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        { attrs: { to: "/certification" } },
        [
          _c("font-awesome-icon", { attrs: { icon: "asterisk" } }),
          _vm._v(" "),
          _c("span", {
            domProps: {
              textContent: _vm._s(_vm.$t("global.menu.entities.certification")),
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        { attrs: { to: "/cohort" } },
        [
          _c("font-awesome-icon", { attrs: { icon: "asterisk" } }),
          _vm._v(" "),
          _c("span", {
            domProps: {
              textContent: _vm._s(_vm.$t("global.menu.entities.cohort")),
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.hasAnyAuthority("ROLE_ADMIN") && _vm.authenticated
        ? _c(
            "b-dropdown-item",
            { attrs: { to: "/technology-term" } },
            [
              _c("font-awesome-icon", { attrs: { icon: "asterisk" } }),
              _vm._v(" "),
              _c("span", {
                domProps: {
                  textContent: _vm._s(
                    _vm.$t("global.menu.entities.technologyTerm")
                  ),
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.hasAnyAuthority("ROLE_ADMIN") && _vm.authenticated
        ? _c(
            "b-dropdown-item",
            { attrs: { to: "/client", "data-cy": "client-menu" } },
            [
              _c("font-awesome-icon", { attrs: { icon: "asterisk" } }),
              _vm._v(" "),
              _c("span", {
                domProps: {
                  textContent: _vm._s(_vm.$t("global.menu.entities.client")),
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        { attrs: { to: "/client-department" } },
        [
          _c("font-awesome-icon", { attrs: { icon: "asterisk" } }),
          _vm._v(" "),
          _c("span", {
            domProps: {
              textContent: _vm._s(
                _vm.$t("global.menu.entities.clientDepartment")
              ),
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        { attrs: { to: "/company" } },
        [
          _c("font-awesome-icon", { attrs: { icon: "asterisk" } }),
          _vm._v(" "),
          _c("span", {
            domProps: {
              textContent: _vm._s(_vm.$t("global.menu.entities.company")),
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.hasAnyAuthority("ROLE_ADMIN") && _vm.authenticated
        ? _c(
            "b-dropdown-item",
            { attrs: { to: "/team-request" } },
            [
              _c("font-awesome-icon", { attrs: { icon: "asterisk" } }),
              _vm._v(" "),
              _c("span", {
                domProps: {
                  textContent: _vm._s(
                    _vm.$t("global.menu.entities.teamRequest")
                  ),
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        { attrs: { to: "/notification" } },
        [
          _c("font-awesome-icon", { attrs: { icon: "asterisk" } }),
          _vm._v(" "),
          _c("span", {
            domProps: {
              textContent: _vm._s(_vm.$t("global.menu.entities.notification")),
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.hasAnyAuthority("ROLE_ADMIN") && _vm.authenticated
        ? _c(
            "b-dropdown-item",
            { attrs: { to: "/activity-log" } },
            [
              _c("font-awesome-icon", { attrs: { icon: "asterisk" } }),
              _vm._v(" "),
              _c("span", {
                domProps: {
                  textContent: _vm._s(
                    _vm.$t("global.menu.entities.activityLog")
                  ),
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        { attrs: { to: "/client-rate" } },
        [
          _c("font-awesome-icon", { attrs: { icon: "asterisk" } }),
          _vm._v(" "),
          _c("span", {
            domProps: {
              textContent: _vm._s(_vm.$t("global.menu.entities.clientRate")),
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        { attrs: { to: "/cohort-request" } },
        [
          _c("font-awesome-icon", { attrs: { icon: "asterisk" } }),
          _vm._v(" "),
          _c("span", {
            domProps: {
              textContent: _vm._s(_vm.$t("global.menu.entities.cohortRequest")),
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        { attrs: { to: "/team-request-proposal" } },
        [
          _c("font-awesome-icon", { attrs: { icon: "asterisk" } }),
          _vm._v(" "),
          _c("span", {
            domProps: {
              textContent: _vm._s(
                _vm.$t("global.menu.entities.teamRequestProposal")
              ),
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        { attrs: { to: "/soft-skill" } },
        [
          _c("font-awesome-icon", { attrs: { icon: "asterisk" } }),
          _vm._v(" "),
          _c("span", {
            domProps: {
              textContent: _vm._s(_vm.$t("global.menu.entities.softSkill")),
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }