var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      class: [
        "d-flex",
        "cursor-pointer",
        "px-4",
        "py-2",
        {
          "wrapper-border": _vm.isActive === false,
          "wrapper-active-border": _vm.isActive === true,
        },
      ],
      staticStyle: { "background-color": "transparent" },
      attrs: { "data-cy": "experience-level-card" },
      on: {
        mouseover: function ($event) {
          _vm.isHovered = true
        },
        mouseleave: function ($event) {
          _vm.isHovered = false
        },
        click: _vm.handleClick,
      },
    },
    [
      _c(
        "div",
        { staticClass: "mr-2", attrs: { "data-cy": "experience-level-text" } },
        [
          _c(
            "span",
            {
              class: [
                "text-body-1",
                {
                  "text-body-1-bold primary--text text--base":
                    _vm.isActive === true,
                  "text--text text--base":
                    _vm.isHovered === false && _vm.isActive === false,
                  "primary--text text--base":
                    _vm.isHovered === true && _vm.isActive === false,
                },
              ],
            },
            [_vm._v("\n      " + _vm._s(_vm.seniorityLevelText) + "\n    ")]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "d-flex align-center",
          attrs: { "data-cy": "experience-level-stars" },
        },
        [
          _vm._l(1 + _vm.value, function (n) {
            return _c(
              "div",
              { attrs: { "data-cy": "experience-level-star-filled" } },
              [
                _vm.isHovered === false && _vm.isActive === false
                  ? _c("v-img", {
                      attrs: {
                        width: 20,
                        src: "/content/svgs/star/star-fill-grey.svg",
                        "data-cy": "grey-filled-star",
                      },
                    })
                  : _c("v-img", {
                      attrs: {
                        width: 20,
                        src: "/content/svgs/star/star-fill-blue.svg",
                        "data-cy": "blue-filled-star",
                      },
                    }),
              ],
              1
            )
          }),
          _vm._v(" "),
          _vm._l(2 - _vm.value, function (n) {
            return _c(
              "div",
              { attrs: { "data-cy": "experience-level-star-empty" } },
              [
                _c("v-img", {
                  attrs: {
                    width: 20,
                    src: "/content/svgs/star/star-empty-grey-lighten1.svg",
                  },
                }),
              ],
              1
            )
          }),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }