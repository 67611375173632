var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-btn",
    {
      staticClass: "cursor-pinter text-none p-0",
      attrs: { text: "", "data-cy": "logout-button" },
      on: { click: _vm.onClick },
    },
    [
      _c(
        "v-container",
        {
          staticClass: "cursor-pointer px-3",
          attrs: { "data-cy": "logout-button-container" },
        },
        [
          _c(
            "v-row",
            { attrs: { align: "center", "no-gutters": "" } },
            [
              _c(
                "v-col",
                {
                  staticClass: "p-0",
                  attrs: { "data-cy": "logout-button-icon-col" },
                },
                [
                  _c("v-img", {
                    attrs: {
                      width: 24,
                      src: "/content/svgs/logout.svg",
                      "data-cy": "logout-button-icon",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                {
                  staticClass:
                    "pl-2 pr-0 text--text text--lighten-1 text-body-1-medium",
                  attrs: { "data-cy": "logout-button-text" },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("global.menu.account.logout")) +
                      "\n      "
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }