import { Authority } from '@/shared/security/authority';
import axios from 'axios';
import { GoogleOAuthProvider } from 'google-oauth-gsi';
import { Component, Emit, Inject, Vue } from 'vue-property-decorator';

@Component({
  name: 'GsingComponent',
  props: {},
})
export default class gsing extends Vue {
  public hostname = window.location.hostname;
  public url = 'https://oauth2.googleapis.com/token';
  public clientId = '__clientId__';
  public clientSecret = '__clientSecret__';
  public redirectUri = '';

  public code = '';
  public grantType = 'authorization_code';

  public data = {
    client_id: this.clientId,
    client_secret: this.clientSecret,
    code: this.code,
    grant_type: this.grantType,
    redirect_uri: this.redirectSelector(this.hostname),
    login_uri: 'https://accounts.google.com/o/oauth2/v2/auth',
  };

  public redirectSelector(name) {
    switch (name) {
      case 'platform-pre.stemdo.io':
        return 'https://platform-pre.stemdo.io/login';
      case 'platform.stemdo.io':
        return 'https://platform.stemdo.io/login';
      case 'platform-dev.stemdo.io':
        return 'https://platform-dev.stemdo.io/login';
      case 'localhost':
        return 'http://localhost:9000/login';
    }
  }

  public mounted() {
    this.data = {
      client_id: this.clientId,
      client_secret: this.clientSecret,
      code: this.code,
      grant_type: this.grantType,
      redirect_uri: this.redirectSelector(this.hostname),
      login_uri: 'https://accounts.google.com/o/oauth2/v2/auth',
    };
    console.log(this.redirectUri.slice(this.redirectUri.length, 1));
  }
  public authority = Authority;
  public googleProvider = new GoogleOAuthProvider({
    clientId: this.clientId,
    onScriptLoadError: () => console.log('Google Service OFF'),
    onScriptLoadSuccess: () => console.log('Google Service ON'),
  });

  public login = this.googleProvider.useGoogleLogin({
    flow: 'auth-code',
    ux_mode: 'redirect',
    redirect_uri: this.redirectSelector(this.hostname),

    onSuccess: codeResponse => {
      this.data.code = codeResponse.code;

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams(this.data),
      };
      /**
       *
       * TENEMOS ACCESS_TOKEN <--
       *
       */
      fetch(this.url, options)
        .then(response => response.json())
        .then(data => {
          /**
           *
           *  TENEMOS YA EL DATO
           *
           */

          const url2 = 'https://www.googleapis.com/userinfo/v2/me?access_token=' + data.access_token;
          axios
            .get(url2)
            .then(res => {})
            .catch(err => {
              console.log(err);
            });
        })
        .catch(error => {
          console.error('Error:', error);
        });
    },

    onError: err => console.error('Failed to login with google', err),
  });
}
