var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    {
      staticClass: "m-0 px-8 pt-6",
      attrs: { id: "stemdoers-wrapper", fluid: "" },
    },
    [
      _c(
        "v-col",
        {
          staticClass: "pa-0 ma-0 d-flex align-center",
          attrs: { cols: "2", fluid: "" },
        },
        [
          _c("h3", {
            staticClass: "text-h3",
            staticStyle: { color: "var(--v-text-base)" },
            attrs: { id: "title-page-1" },
            domProps: {
              textContent: _vm._s(_vm.$t("stemdoers-filter-list.title")),
            },
          }),
        ]
      ),
      _vm._v(" "),
      _c(
        "v-container",
        {
          staticClass:
            "d-flex stemdoers-tip justify-space-between align-items-center mx-0 mb-0 pl-0 pt-0",
          attrs: { fluid: "", "data-cy": "stemdoers-tip-container" },
        },
        [
          _c("span", {
            staticClass: "text-subtitle-3 mt-1",
            attrs: { "data-cy": "stemdoers-tip-text" },
            domProps: {
              textContent: _vm._s(_vm.$t("stemdoers-filter-list.tip")),
            },
          }),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mt-4" },
        [
          _c("StemdoersFilter", {
            attrs: {
              stemdoers: _vm.stemdoers,
              onVerticalChange: _vm.handleVerticalChange,
              onStatusChange: _vm.handleStatusChange,
              onSearch: _vm.handleSearchChange,
            },
          }),
          _vm._v(" "),
          _c("StemdoersList", {
            attrs: {
              stemdoers: _vm.filteredStemdoers,
              displaySkeleton: _vm.displaySkeleton,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }