import { IStemdoer, Stemdoer } from '@/shared/model/stemdoer.model';
import ResourceService from '@/shared/ResourceService.service';
import SAvatarStemdoer from '@/components/stemdo-components/s-avatar-stemdoer/SAvatarStemdoer.vue';
import SButton from '@/components/stemdo-components/s-button/SButton.vue';
import { defineComponent, inject, onBeforeMount, onMounted, ref, watch } from 'vue';
import { useStore } from '@/plugins/vuex';
import { computed } from 'vue';
import { IRate } from '@/shared/model/rate.model';
import { useI18N } from '@/plugins/i18n';
import moment from 'moment';
import { ExperienceCollection } from '@/shared/model/experience-collection.model'; //need it when come from teamproposal
import SReadMore from '@/components/stemdo-components/s-read-more/SReadMore.vue';
import SLanguageIcon from '@/components/stemdo-components/s-language-icon/SLanguageIcon.vue';
import { StemdoerStatus } from '@/shared/model/enumerations/stemdoer-status.model';
import StemdoerAvailability from './StemdoerAvailability.vue';
import SShareLink from '@/components/stemdo-components/s-share-link/SShareLink.vue';
import SPopUp from '@/components/stemdo-components/s-pop-up/SPopUp.vue';

export default defineComponent({
  props: {
    stemdoer: {
      type: Object as () => IStemdoer,
      required: true
    },
    rates: {
      type: Array as () => IRate[],
      required: false
    },
    rate: {
      type: Number,
      required: false
    },
    onAddToCart: {
      type: Function,
      required: true
    }
  },
  components: {
    SAvatarStemdoer,
    SReadMore,
    SButton,
    SLanguageIcon,
    StemdoerAvailability,
    SShareLink,
    SPopUp
  },

  setup(props) {
    const store = useStore();
    const i18n = useI18N();
    const stemdoerMutable = ref<Stemdoer>(new Stemdoer(props.stemdoer));
    const resourceService: ResourceService = inject('resourceService');
    const descriptionArray = ref([]);
    const loading = ref(false);
    const cart = computed(() => store.getters['cartStore/cartItems']);
    const clientSelected = store.getters['cartStore/clientSelected'];
    const needHide = ref(true);
    const stemdoerisInCart = computed(() => {
      return cart.value.some(item => item.id === props.stemdoer['id']);
    });
    const sPopUpData = ref({
      open: false,
      text: ''
    });

    onMounted(() => {
      const urlParams = new URLSearchParams(window.location.search);
      needHide.value = !urlParams.has('h');
      stemdoerMutable.value.technologyScores.orderByScoreDesc();
      if (stemdoerMutable.value.experience) {
        const experienceCollection = new ExperienceCollection(stemdoerMutable.value.experience);
        stemdoerMutable.value.experience = experienceCollection.groupByCompany();
      }
    });

    watch(
      () => props.stemdoer,
      value => {
        sPopUpData.value.open = false;
        stemdoerMutable.value = new Stemdoer(value);
        stemdoerMutable.value.technologyScores.orderByScoreDesc();
        if (stemdoerMutable.value.experience) {
          const experienceCollection = new ExperienceCollection(stemdoerMutable.value.experience);
          stemdoerMutable.value.experience = experienceCollection.groupByCompany();
        }
      }
    );

    const handleAddToCartBtn = () => {
      const stemdoerWithAvailability = props.stemdoer as { availability: { available: boolean } };
      if (stemdoerWithAvailability.availability.available) {
        props.onAddToCart((props.stemdoer as IStemdoer).id);
      }
    };

    const getRate = (seniority: string) => {
      let rate = props.rate ?? 36;
      let ratesSource: any[] = [];
      if (props.rates && props.rates.length > 0) {
        ratesSource = props.rates;
      } else if (clientSelected && store.getters.account?.authorities.includes('ROLE_GESTOR')) {
        ratesSource = clientSelected.clientRate?.rates || [];
      }
      if (Array.isArray(ratesSource)) {
        ratesSource.forEach(item => {
          if (item.seniority.toLowerCase().replace(/[-_]/g, '') == seniority.toLowerCase().replace(/[-_]/g, '')) {
            rate = item.value;
          }
        });
      }
      return rate;
    };

    const getStarSkills = (skills: number) => {
      const skillLevel = skills;
      if (skillLevel <= 3) {
        return (
          '<img src="/content/svgs/star/star-fill-grey.svg" />' +
          '<img src="/content/svgs/star/star-empty-grey.svg" />' +
          '<img src="/content/svgs/star/star-empty-grey.svg" />'
        );
      } else if (skillLevel <= 5) {
        return (
          '<img src="/content/svgs/star/star-fill-grey.svg" />' +
          '<img src="/content/svgs/star/star-fill-grey.svg" />' +
          '<img src="/content/svgs/star/star-empty-grey.svg" />'
        );
      } else if (skillLevel >= 6) {
        return (
          '<img src="/content/svgs/star/star-fill-grey.svg" />' +
          '<img src="/content/svgs/star/star-fill-grey.svg" />' +
          '<img src="/content/svgs/star/star-fill-grey.svg" />'
        );
      }
    };

    const getStarsTooltip = (skills: number) => {
      let tooltip = 0;
      if (skills <= 3) {
        return (tooltip = 1);
      } else if (skills <= 5) {
        return (tooltip = 2);
      } else if (skills >= 6) {
        return (tooltip = 3);
      }
    };

    const getInfoBadge = (skills: number, id: number) => {
      let translationKey;
      if (skills <= 3) {
        translationKey = 'technologyMaster.tech-badges.' + id + '.1';
      } else if (skills <= 5) {
        translationKey = 'technologyMaster.tech-badges.' + id + '.2';
      } else if (skills >= 6) {
        translationKey = 'technologyMaster.tech-badges.' + id + '.3';
      }
      let translation = i18n.t(translationKey);
      if (translation === translationKey) {
        translation = i18n.t(translationKey.replace(id.toString(), '0'));
      }
      return translation;
    };

    const getTitleBadge = (skills: number) => {
      if (skills <= 3) {
        return i18n.t('technologyMaster.tech-badges.tier.1');
      } else if (skills <= 5) {
        return i18n.t('technologyMaster.tech-badges.tier.2');
      } else if (skills >= 6) {
        return i18n.t('technologyMaster.tech-badges.tier.3');
      }
    };

    const getTech = (techs, limit?) => {
      if (techs && techs.includes(';')) {
        const techList = techs.split(';').map(word => {
          return word.charAt(0).toUpperCase() + word.slice(1);
        });
        if (limit === undefined) return techList.slice(0, limit);
        return techList;
      } else {
        return [];
      }
    };

    const getTotalTime = time => {
      const years = Math.floor(time / 12);
      const months = time % 12;
      let totalTime = '';
      if (years !== 0) {
        totalTime += years + ' ' + (years > 1 ? i18n.t('explore.stemdoer-detail.years') : i18n.t('explore.stemdoer-detail.year')) + ' ';
      }
      if (months !== 0) {
        totalTime += months + ' ' + (months > 1 ? i18n.t('explore.stemdoer-detail.months') : i18n.t('explore.stemdoer-detail.month')) + ' ';
      }
      return totalTime.trim();
    };

    const isStemdoExp = company => {
      if (company == null) return false;
      return company.toLowerCase() == 'stemdo';
    };

    const sectorFormatted = (text: string | null | undefined) => {
      if (text == null) {
        return '';
      }
      return `(${text.toUpperCase()})`;
    };

    const shouldDisplayEnglishBadge = (english: string | undefined) => {
      return english != 'A1' && english != 'A2' && english != '' && english != undefined;
    };

    const openPopUp = (open: boolean, text: string) => {
      sPopUpData.value.open = open;
      sPopUpData.value.text = text;
    };

    return {
      stemdoerMutable,
      descriptionArray,
      loading,
      stemdoerisInCart,
      needHide,
      StemdoerStatus,
      sPopUpData,
      openPopUp,
      sectorFormatted,
      getInfoBadge,
      getStarSkills,
      getTech,
      isStemdoExp,
      getRate,
      getTotalTime,
      getTitleBadge,
      handleAddToCartBtn,
      shouldDisplayEnglishBadge,
      getStarsTooltip
    };
  }
});
