import { CardOptions } from '@/components/stemdo-components/s-card-stemdoer/sCardStemdoer';
import { StemdoerRates } from '@/shared/model/stemdoer-rate.model';
import { defineComponent, PropType, ref, watch } from 'vue';
import { ExtendedTeamRequestStatus } from '../enum/extendedTeamRequestStatus';
import ModalStemdoerDetail from '../../stemdoer-detail/ModalStemdoerDetail.vue';
import { IStemdoer } from '@/shared/model/stemdoer.model';

export default defineComponent({
  props: {
    stemdoerRates: {
      //@ts-ignore
      type: Array as () => StemdoerRates,
      required: true
    },
    teamRequestStatus: {
      type: String as PropType<ExtendedTeamRequestStatus>
    },
    onStemdoerRateChange: {
      type: Function as PropType<(stemdoerId: string, newRate: number) => void>
    }
  },
  components: {
    ModalStemdoerDetail
  },
  setup(props: {
    onStemdoerRateChange: (stemdoerId: string, newRate: number) => void;
    stemdoerRates: StemdoerRates;
    teamRequestStatus: ExtendedTeamRequestStatus;
  }) {
    const showNotAvailableStemdoers = ref(false);
    const availablesStemdoerRates = ref<StemdoerRates>(StemdoerRates.empty());
    const notAvailablesStemdoerRates = ref<StemdoerRates>(StemdoerRates.empty());
    const areStemdoersUnavailable = ref<boolean>(false);
    const isStemdoerDetailModalOpen = ref(false);
    const selectedStemdoer = ref<IStemdoer>({});
    const selectedStemdoerRate = ref<number>(0);
    const cardOptions: CardOptions = {
      isClickable: true,
      onClick: (stemdoerId: string) => {
        const stemdoerRate = props.stemdoerRates.findByStemdoerId(stemdoerId);
        selectedStemdoer.value = stemdoerRate.stemdoer;
        selectedStemdoerRate.value = stemdoerRate.rate;
        isStemdoerDetailModalOpen.value = true;
      }
    };
    const disabledCardOptions: CardOptions = {
      isDisabled: true
    };

    watch(
      () => props.teamRequestStatus,
      newStatus => {
        areStemdoersUnavailable.value =
          ExtendedTeamRequestStatus.MANAGER_AND_VALIDATED_AND_NOT_ALL_STEMDOERS_AVAILABLE == newStatus ||
          ExtendedTeamRequestStatus.CLIENT_AND_VALIDATED_AND_NOT_ALL_STEMDOERS_AVAILABLE == newStatus;
      },
      { immediate: true }
    );

    const closeStemdoerDetailModal = () => {
      isStemdoerDetailModalOpen.value = false;
    };

    const checkStemdoersAvailability = () => {
      if (
        (typeof props.stemdoerRates['areAllStemdoersAvailable'] === 'function' &&
          props.stemdoerRates?.areAllStemdoersAvailable() === false &&
          (props.teamRequestStatus === ExtendedTeamRequestStatus.CLIENT_AND_VALIDATED_AND_NOT_ALL_STEMDOERS_AVAILABLE ||
            props.teamRequestStatus === ExtendedTeamRequestStatus.CLIENT_AND_SAVED_AND_NOT_ALL_STEMDOERS_AVAILABLE)) ||
        props.teamRequestStatus === ExtendedTeamRequestStatus.MANAGER_AND_VALIDATED_AND_NOT_ALL_STEMDOERS_AVAILABLE ||
        props.teamRequestStatus === ExtendedTeamRequestStatus.MANAGER_AND_PENDING_AND_USER_MODIFIED
      ) {
        showNotAvailableStemdoers.value = true;
        availablesStemdoerRates.value = props.stemdoerRates.getAvailableStemdoers();
        notAvailablesStemdoerRates.value = props.stemdoerRates.getNotAvailableStemdoers();
      } else {
        showNotAvailableStemdoers.value = false;
      }
    };

    const updateCardOptions = () => {
      switch (props.teamRequestStatus) {
        case ExtendedTeamRequestStatus.MANAGER_AND_PENDING_AND_USER_MODIFIED:
        case ExtendedTeamRequestStatus.MANAGER_AND_PENDING_AND_USER_NOT_MODIFIED:
        case ExtendedTeamRequestStatus.MANAGER_AND_VALIDATED_AND_USER_MODIFIED:
        case ExtendedTeamRequestStatus.MANAGER_AND_VALIDATED_AND_USER_NOT_MODIFIED:
          cardOptions.isRateEditable = true;
          cardOptions.onRateChange = props.onStemdoerRateChange;
          break;
        case ExtendedTeamRequestStatus.CLIENT_AND_CANCELLED:
        case ExtendedTeamRequestStatus.CLIENT_AND_SAVED:
        case ExtendedTeamRequestStatus.CLIENT_AND_SAVED_AND_NOT_ALL_STEMDOERS_AVAILABLE:
        case ExtendedTeamRequestStatus.CLIENT_AND_PENDING:
        case ExtendedTeamRequestStatus.CLIENT_AND_VALIDATED_AND_MODIFIED_BY_MANAGER:
        case ExtendedTeamRequestStatus.CLIENT_AND_VALIDATED_AND_NOT_MODIFIED_BY_MANAGER:
        case ExtendedTeamRequestStatus.CLIENT_AND_VALIDATED_AND_NOT_ALL_STEMDOERS_AVAILABLE:
        case ExtendedTeamRequestStatus.CLIENT_AND_ON_GOING:
          cardOptions.isRateVisible = true;
          break;
        default:
          break;
      }
    };

    watch(() => props.stemdoerRates, checkStemdoersAvailability, { immediate: true });
    watch(() => props.teamRequestStatus, updateCardOptions, { immediate: true });

    return {
      selectedStemdoer,
      selectedStemdoerRate,
      isStemdoerDetailModalOpen,
      showNotAvailableStemdoers,
      availablesStemdoerRates,
      notAvailablesStemdoerRates,
      areStemdoersUnavailable,
      cardOptions,
      disabledCardOptions,
      closeStemdoerDetailModal
    };
  }
});
