import FilterSingle from './filter-single.model';

class SelectionMultipleFilter extends FilterSingle {
  filterValues: any[];

  constructor(field: string, values: any[]) {
    super(field, field);
    this.filterValues = values;
  }

  filter(entity: any): boolean {
    if (this.filterValues.length === 0) return true;
    const fieldValue = this.getFieldValueOf(entity);

    if (Array.isArray(fieldValue)) {
      return fieldValue.some(value => this.filterValues.includes(value.id));
    }
    return this.filterValues.includes(fieldValue);
  }

  toString(): string {
    return `${this.field}=[${this.filterValues.join(',')}]`;
  }
}

export default SelectionMultipleFilter;
