var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "py-2 px-5",
      attrs: { "data-cy": "availability-switch-container" },
    },
    [
      _c("v-switch", {
        staticClass: "custom-switch-availability mt-0 pt-0",
        attrs: { color: "primary", "data-cy": "availability-switch" },
        on: { change: _vm.handleChange },
        scopedSlots: _vm._u([
          {
            key: "label",
            fn: function () {
              return [
                _c(
                  "span",
                  {
                    staticClass: "text-subtitle-3 text--text text--base",
                    attrs: { "data-cy": "availability-switch-label" },
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm.$t(
                            "explore.more-filters.modal.filter.filter-label-availability"
                          )
                        ) +
                        "\n      "
                    ),
                  ]
                ),
              ]
            },
            proxy: true,
          },
        ]),
        model: {
          value: _vm.value,
          callback: function ($$v) {
            _vm.value = $$v
          },
          expression: "value",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }