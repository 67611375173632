import { inject, onMounted, ref } from 'vue';
import TranslationService from '@/locale/translation.service';
import { useI18N } from '@/plugins/i18n';
import { useStore } from '@/plugins/vuex';

export default {
  setup() {
    const i18n = useI18N();
    const translationService: TranslationService = inject('translationService');
    const currentLanguage = ref(undefined);
    const store = useStore();

    const languages = [
      { id: 'en', desc: 'English', svg: 'english-uk-lang' },
      { id: 'es', desc: 'Español', svg: 'spanish-es-lang' }
    ];

    const updateCurrentLanguage = () => {
      const localeFromI18n = i18n.locale.split('-')[0];
      currentLanguage.value = languages.some(lang => lang.id === localeFromI18n) ? localeFromI18n : store.getters.currentLanguage;
    };

    const changeLanguage = (lang: string) => {
      translationService.refreshTranslation(lang);
    };

    onMounted(() => {
      updateCurrentLanguage();
    });

    return { currentLanguage, languages, changeLanguage };
  }
};
