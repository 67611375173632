import btnMyTeam from '@/sections/shared/explore/btn-my-team/btnMyTeam';
import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';

Vue.use(Vuetify);

const opts = {
  theme: {
    options: { customProperties: true },
    themes: {
      light: {
        primary: {
          base: '#4237FF'
        },
        secondary: {
          base: '#8859FF',
          lighten1: '#F3EEFF'
        },
        secondaryCancel: {
          base: '#606061',
          lighten1: '#F3EEFF'
        },
        tertiary: {
          base: '#00C2B1',
          lighten1: '#E5F9F7',
          darken1: '#F9F9F9'
        },
        quaternary: {
          base: '#EF2630',
          lighten1: '#FFEBEC'
        },
        quinary: {
          base: '#FFA337',
          lighten1: '#FFF6EB'
        },
        senary: {
          base: '#ECECEF',
          lighten1: '#F9F9F9'
        },
        text: {
          base: '#222226',
          lighten1: '#606061',
          lighten2: '#DBDBE0',
          lighten3: '#ECECEF',
          lighten4: '#F9F9F9'
        },
        background: {
          base: '#FFFFFF',
          darken1: '#F9F9F9',
          darken2: '#ECECEF',
          darken3: '#222226'
        },
        border: {
          base: '#ECECEF',
          darken1: '#DBDBE0'
        },
        btnPrimary: {
          base: '#4237FF',
          darken1: '#0C00D1'
        },
        btnPrimaryText: {
          base: '#FFFFFF'
        },
        btnSecondary: {
          base: '#4237FF',
          darken1: '#0C00D1'
        },
        btnSecondaryBorder: {
          base: '#DBDBE0'
        },
        btnTertiary: {
          base: '#606061',
          darken1: '#444'
        },
        btnTertiaryBorder: {
          base: '#DBDBE0'
        },
        btnQuaternary: {
          base: '#EF2630',
          darken1: '#E0242D'
        },
        btnQuaternaryText: {
          base: '#FFFFFF'
        },
        btnQuaternaryBorder: {
          base: '#DBDBE0'
        }
      }
    }
  }
};

export default new Vuetify(opts);
