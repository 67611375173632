var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    {
      staticClass: "mx-0 pl-0 pt-0 pb-0",
      attrs: {
        id: "candidate-filter",
        fluid: "",
        "data-cy": "candidate-filter-container",
      },
    },
    [
      _c(
        "v-card-title",
        {
          staticClass: "d-flex align-center filter-container",
          attrs: { "data-cy": "candidate-filter-bar" },
        },
        [
          _c(
            "v-row",
            {
              staticClass: "d-flex align-center p-2",
              attrs: { "data-cy": "candidate-filter-row" },
            },
            [
              _c("v-col", {
                staticClass: "text-left p-0 pl-4",
                attrs: { cols: "12" },
              }),
              _vm._v(" "),
              _c(
                "v-col",
                {
                  staticClass: "p-2 pl-4 pr-4 custom-v-search",
                  attrs: { "data-cy": "candidate-filter-search-col" },
                },
                [
                  _c("v-text-field", {
                    staticClass: "custom-text-field",
                    attrs: {
                      "data-cy": "candidate-filter-search-input",
                      density: "compact",
                      label: _vm.$t("candidate.filter.search"),
                      "prepend-inner-icon": "mdi-magnify",
                      variant: "solo-filled",
                      flat: "",
                      solo: "",
                      dense: "",
                      "hide-details": "",
                    },
                    nativeOn: {
                      input: function ($event) {
                        return _vm.updateSearch.apply(null, arguments)
                      },
                    },
                    model: {
                      value: _vm.search,
                      callback: function ($$v) {
                        _vm.search = $$v
                      },
                      expression: "search",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }