import { defineComponent, inject, onMounted, PropType, ref, watch } from 'vue';
import { Stemdoer } from '@/shared/model/stemdoer.model';
import CarbonFootprintService from '@/sections/admin/entities/carbon-footprint/carbonFootprint.service';

export default defineComponent({
  props: {
    stemdoers: {
      type: Array as PropType<Stemdoer[]>,
      required: true
    }
  },
  setup(props) {
    const carbonFootprintService: CarbonFootprintService = inject('carbonFootprintService');
    const footprint = ref<number>(0);

    onMounted(() => {
      getFootPrint();
    });

    watch(
      () => props.stemdoers,
      () => {
        getFootPrint();
      }
    );

    const getFootPrint = () => {
      const stemdoersIds = props.stemdoers.map(stemdoer => stemdoer.id);
      carbonFootprintService
        .getFootPrint(stemdoersIds)
        .then(footp => {
          footprint.value = Math.floor(Number(footp) * 10) / 10;
        })
        .catch(err => {
          footprint.value = stemdoersIds.length * 6;
        });
    };

    const footprintCircle = a => {
      const footp = a;
      const circumference = 2 * Math.PI * 67;
      let strokeDasharray, offset;
      if (footp > 100) {
        strokeDasharray = `${circumference} ${circumference}`;
        offset = 0;
      } else {
        strokeDasharray = `${circumference} ${circumference}`;
        offset = ((100 - Number(footp)) / 100) * circumference;
        offset = Math.min(offset, circumference);
      }

      return {
        'stroke-dasharray': strokeDasharray,
        'stroke-dashoffset': offset
      };
    };

    return { footprint, footprintCircle };
  }
});
