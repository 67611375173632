import axios from 'axios';
import localStorageFacade, { StorageKey } from '@/shared/local-store-facade/LocalStoreFacade';

const baseApiUrl = 'api';

export default class CarbonFootprintService {
  public getFootPrint(stemdoerIds: string[]): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      axios
        .get(baseApiUrl + '/footprint?stemdoerID=' + stemdoerIds.join(','), {
          headers: {
            Authorization: 'Bearer ' + localStorageFacade.get(StorageKey.AUTH_TOKEN)
          },
          responseType: 'json'
        })
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
