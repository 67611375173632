var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-navigation-drawer",
    {
      attrs: {
        color: "background darken-1",
        permanent: "",
        left: "",
        app: "",
        "data-cy": "main-drawer",
      },
      scopedSlots: _vm._u([
        {
          key: "prepend",
          fn: function () {
            return [
              _c("v-list-item", {
                staticClass: "m-0 p-0",
                staticStyle: {
                  "max-height": "32px",
                  "min-height": "32px !important",
                },
              }),
              _vm._v(" "),
              _c(
                "v-list-item",
                {
                  staticClass: "ml-4 mt-n2",
                  attrs: { "data-cy": "drawer-logo-container" },
                },
                [
                  _c(
                    "v-list-item-icon",
                    { staticClass: "mt-0" },
                    [
                      _c("v-img", {
                        attrs: {
                          width: 164,
                          src: "/content/images/stemdo-black-logo.png",
                          "data-cy": "drawer-logo-img",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
        {
          key: "append",
          fn: function () {
            return [
              _c(
                "v-list",
                {
                  staticClass: "mb-5",
                  attrs: {
                    width: "100%",
                    dense: "",
                    nav: "",
                    "data-cy": "drawer-append-list",
                  },
                },
                [
                  _c(
                    "v-list-item",
                    {
                      staticClass: "ml-3",
                      attrs: { "data-cy": "drawer-lang-select-item" },
                    },
                    [
                      _c("SSelectLang", {
                        attrs: { "data-cy": "drawer-lang-select" },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-list-item",
                    {
                      staticClass: "ml-3",
                      attrs: { "data-cy": "drawer-logout-item" },
                    },
                    [
                      _c("SButtonLogout", {
                        attrs: { "data-cy": "drawer-logout-button" },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-list-item",
                    {
                      staticClass: "ml-3 justify-center",
                      staticStyle: {
                        "max-height": "18px",
                        "min-height": "18px",
                      },
                      attrs: { "data-cy": "drawer-version-item" },
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "text--text text--lighten-1 text-body-3",
                          attrs: { "data-cy": "drawer-version-text" },
                        },
                        [_vm._v(" v" + _vm._s(_vm.version) + " ")]
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _vm._v(" "),
      _c(
        "v-list",
        {
          staticClass: "custom-nav mt-4",
          attrs: {
            width: "100%",
            dense: "",
            nav: "",
            "data-cy": "drawer-main-list",
          },
        },
        [
          _c(
            "div",
            { attrs: { "active-class": "active-item" } },
            _vm._l(_vm.items, function (item, index) {
              return _c("MainDrawerItem", {
                key: item.title,
                class: [{ "mb-3": index !== _vm.items.length - 1 }],
                attrs: { item: item, "data-cy": "drawer-list-item" },
              })
            }),
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }