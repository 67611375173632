var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    {
      staticClass: "mx-0 mb-8 pl-0 pt-0",
      attrs: {
        id: "request-list",
        fluid: "",
        "data-cy": "team-requests-list-container",
      },
    },
    [
      _vm.displaySkeleton
        ? _c("SSkeletonTable", {
            attrs: {
              "data-cy": "team-requests-skeleton",
              headers: _vm.headers,
            },
          })
        : _c("v-data-table", {
            staticClass: "remove-box-shadow manager-request-table",
            attrs: {
              "data-cy": "team-requests-data-table",
              headers: _vm.headers,
              items: _vm.teamRequestsInPage,
              "disable-sort": "",
              "hide-default-footer": "",
            },
            on: { "click:row": _vm.handleClickDetail },
            scopedSlots: _vm._u([
              {
                key: "item.stemdoers",
                fn: function ({ item }) {
                  return [
                    _c(
                      "div",
                      {
                        staticClass: "chip-container",
                        attrs: { "data-cy": "team-requests-stemdoers-cell" },
                      },
                      [
                        _vm._l(
                          item.stemdoerRates,
                          function (stemdoerRate, index) {
                            return [
                              index < 3
                                ? _c(
                                    "v-chip",
                                    {
                                      key: "chip-rate-" + index,
                                      staticClass:
                                        "overlapping-chip no-padding",
                                      attrs: {
                                        color: "transparent",
                                        "data-cy":
                                          "team-requests-stemdoer-chip",
                                      },
                                    },
                                    [
                                      _c("SAvatarStemdoer", {
                                        staticClass: "avatar",
                                        attrs: {
                                          stemdoId: stemdoerRate.stemdoId,
                                          "data-cy":
                                            "team-requests-stemdoer-avatar",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : index === 3
                                ? _c(
                                    "v-chip",
                                    {
                                      key: "chip-rate-" + index,
                                      staticClass:
                                        "overlapping-chip no-padding",
                                      staticStyle: {
                                        border: "1px solid white !important",
                                      },
                                      attrs: {
                                        "data-cy":
                                          "team-requests-stemdoer-chip-remaining",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n            +" +
                                          _vm._s(
                                            item.stemdoerRates.length - 3
                                          ) +
                                          "\n          "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          }
                        ),
                      ],
                      2
                    ),
                  ]
                },
              },
              {
                key: "item.client",
                fn: function ({ item }) {
                  return [
                    _c(
                      "div",
                      {
                        staticClass: "client-info",
                        attrs: { "data-cy": "team-requests-client-cell" },
                      },
                      [
                        _c("span", { staticClass: "text-body-1" }, [
                          _vm._v(
                            " " +
                              _vm._s(item.client?.name) +
                              " " +
                              _vm._s(item.client?.surname) +
                              " "
                          ),
                        ]),
                        _vm._v(" "),
                        item.client &&
                        item.client.company &&
                        item.client.company.name
                          ? _c(
                              "span",
                              {
                                staticClass: "text-body-1-medium",
                                attrs: {
                                  "data-cy": "team-requests-client-company",
                                },
                              },
                              [
                                _vm._v(
                                  "\n          " +
                                    _vm._s(item.client.company.name) +
                                    "\n        "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]
                    ),
                  ]
                },
              },
              {
                key: "item.initDate",
                fn: function ({ item }) {
                  return [
                    item.initDate
                      ? _c(
                          "span",
                          {
                            attrs: { "data-cy": "team-requests-initdate-cell" },
                          },
                          [
                            _vm._v(
                              "\n        " +
                                _vm._s(
                                  _vm.$d(Date.parse(item.initDate), "twoDigit")
                                ) +
                                "\n      "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]
                },
              },
              {
                key: "item.logSummary.lastView",
                fn: function ({ item }) {
                  return [
                    _c(
                      "span",
                      { attrs: { "data-cy": "team-requests-lastview-cell" } },
                      [
                        _vm._v(
                          "\n        " +
                            _vm._s(
                              item.logSummary.lastView
                                ? _vm.$d(
                                    Date.parse(item.logSummary.lastView),
                                    "twoDigit"
                                  )
                                : ""
                            ) +
                            "\n      "
                        ),
                      ]
                    ),
                  ]
                },
              },
              {
                key: "item.logSummary.numberOfViews",
                fn: function ({ item }) {
                  return [
                    _c(
                      "span",
                      {
                        attrs: {
                          "data-cy": "team-requests-number-of-views-cell",
                        },
                      },
                      [
                        _vm._v(
                          "\n        " +
                            _vm._s(item.logSummary.numberOfViews) +
                            "\n        " +
                            _vm._s(
                              item.logSummary.numberOfViews === 1
                                ? _vm.$t("requests-filter-list.list.time")
                                : _vm.$t("requests-filter-list.list.times")
                            ) +
                            "\n      "
                        ),
                      ]
                    ),
                  ]
                },
              },
              {
                key: "item.status",
                fn: function ({ item }) {
                  return [
                    _c(
                      "div",
                      {
                        staticClass: "status-container",
                        attrs: { "data-cy": "team-requests-status-cell" },
                      },
                      [
                        _c("SLabel", {
                          staticClass: "text-body-3 p-1 pl-3 pr-3",
                          attrs: {
                            text: _vm.getStatusDetails(item.status).translation,
                            labelStyle: _vm.getStatusDetails(item.status)
                              .labelStyle,
                            "data-cy": "team-requests-status-label",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                },
              },
              {
                key: "item.action",
                fn: function ({ item }) {
                  return [
                    _c(
                      "router-link",
                      {
                        attrs: {
                          to: {
                            name: "teamRequestByIdManager",
                            params: { id: item.id },
                          },
                          "data-cy": "team-requests-detail-link",
                        },
                      },
                      [
                        _c(
                          "v-btn",
                          {
                            staticClass: "v-icon-detail",
                            attrs: {
                              icon: "",
                              "data-cy": "team-requests-detail-btn",
                            },
                          },
                          [
                            _c("img", {
                              attrs: {
                                src: "content/svgs/eye-outline.svg",
                                alt: "detail",
                                "data-cy": "team-requests-detail-icon",
                              },
                            }),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
      _vm._v(" "),
      !_vm.displaySkeleton
        ? _c(
            "div",
            {
              staticClass: "pagination-container",
              attrs: { "data-cy": "team-requests-pagination" },
            },
            [
              _c("SPagination", {
                attrs: {
                  itemsPerPage: _vm.ITEMS_PER_PAGE,
                  totalItems: _vm.teamRequests.length,
                  onPageChange: _vm.onPageChange,
                  "data-cy": "team-requests-spagination",
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }