import { defineComponent, onMounted, ref } from 'vue';

export default defineComponent({
  props: {
    text: {
      type: String,
      required: true
    },
    maxLength: {
      type: Number,
      default: 300,
      required: false
    }
  },
  setup(props) {
    const isReadingMore = ref<boolean>(false);

    const readMore = () => {
      isReadingMore.value = true;
    };

    onMounted(() => {
      if (props.text.length <= props.maxLength) {
        isReadingMore.value = true;
      }
    });

    return {
      isReadingMore,
      readMore
    };
  }
});
