import axios from 'axios';
import buildPaginationQueryOpts from '@/shared/sort/sorts';
import { IStemdoer } from '@/shared/model/stemdoer.model';
import { IExperience } from '@/shared/model/experience.model';

const baseApiUrl = 'api/stemdoers';
export default class StemdoerService {
  public find(id: string, isHidden?: boolean): Promise<IStemdoer> {
    return new Promise<IStemdoer>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/${id}`, {
          params: {
            isHidden: isHidden
          }
        })
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrieve(paginationQuery?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiUrl + `?${buildPaginationQueryOpts(paginationQuery)}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrieveStemdoersByDate(date: Date, verticalId?: string, paginationQuery?: any): Promise<any> {
    const dateFormatted = date.toISOString().split('T')[0];
    const verticalIdParam = verticalId ? `verticalId=${verticalId}&` : '';
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/date?${verticalIdParam}date=${dateFormatted}&${buildPaginationQueryOpts(paginationQuery)}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public delete(id: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .delete(`${baseApiUrl}/${id}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
  public hideIt(id: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .put(`${baseApiUrl}/hide/${id}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public create(entity: IStemdoer): Promise<IStemdoer> {
    return new Promise<IStemdoer>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public update(entity: IStemdoer): Promise<IStemdoer> {
    return new Promise<IStemdoer>((resolve, reject) => {
      axios
        .put(`${baseApiUrl}/${entity.id}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public partialUpdate(entity: IStemdoer): Promise<IStemdoer> {
    return new Promise<IStemdoer>((resolve, reject) => {
      axios
        .patch(`${baseApiUrl}/${entity.id}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public findExperience(stemdoerId: string, experienceId: string): Promise<IExperience> {
    return axios
      .get(`${baseApiUrl}/${stemdoerId}/experience/${experienceId}`)
      .then(res => res.data)
      .catch(err => {
        console.error(err);
        throw err;
      });
  }

  public createExperience(stemdoerId: string, entity: IExperience): Promise<IExperience> {
    return axios
      .post(`${baseApiUrl}/${stemdoerId}/experience`, entity)
      .then(res => res.data)
      .catch(err => {
        console.error(err);
        throw err;
      });
  }

  public updateExperience(stemdoerId: string, experienceId: string, entity: IExperience): Promise<IExperience> {
    return axios
      .put(`${baseApiUrl}/${stemdoerId}/experience/${experienceId}`, entity)
      .then(res => res.data)
      .catch(err => {
        console.error(err);
        throw err;
      });
  }

  public partialUpdateExperience(stemdoerId: string, experienceId: string, entity: IExperience): Promise<IExperience> {
    return axios
      .patch(`${baseApiUrl}/${stemdoerId}/experience/${experienceId}`, entity)
      .then(res => res.data)
      .catch(err => {
        console.error(err);
        throw err;
      });
  }

  public deleteExperience(stemdoerId: string, experienceId: string): Promise<void> {
    return axios
      .delete(`${baseApiUrl}/${stemdoerId}/experience/${experienceId}`)
      .then(res => res.data)
      .catch(err => {
        console.error(err);
        throw err;
      });
  }

  public retrieveByClientTeams(clientId: string, date: Date, byCompany?: Boolean, paginationQuery?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const formattedDate = date.toISOString().split('T')[0];
      axios
        .get(`${baseApiUrl}/teams/${clientId}?date=${formattedDate}&${buildPaginationQueryOpts(paginationQuery)}`, {
          params: {
            byCompany: byCompany
          }
        })
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
