var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { attrs: { id: "register" } }, [
    _c("div", { staticClass: "login-container" }, [
      _c("div", { staticClass: "login-image", attrs: { "no-validate": "" } }, [
        _c("div", { staticClass: "register-image" }, [
          _c("div", { staticClass: "register-title" }, [
            _c("h1", {
              domProps: {
                textContent: _vm._s(_vm.$t("register.form.subtitle")),
              },
            }),
          ]),
          _vm._v(" "),
          _vm._m(0),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "login-form", attrs: { "no-validate": "" } }, [
        _c("div", { staticClass: "form-text text-body-1" }, [
          _c("div", [
            _c("span", {
              domProps: {
                textContent: _vm._s(
                  _vm.$t("global.messages.info.register.account")
                ),
              },
            }),
            _vm._v(" "),
            _c("a", {
              staticClass: "alert-link text-body-1-bold",
              staticStyle: { color: "#4237ff" },
              attrs: { "data-cy": "registerOpen" },
              domProps: {
                textContent: _vm._s(
                  _vm.$t("global.messages.info.register.toLogin")
                ),
              },
              on: { click: _vm.gotoLogin },
            }),
          ]),
        ]),
        _vm._v(" "),
        !_vm.success
          ? _c(
              "form",
              {
                staticClass: "SForm",
                attrs: {
                  id: "register-form",
                  name: "registerForm",
                  role: "form",
                  "no-validate": "",
                },
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                  },
                },
              },
              [
                _c("div", { staticClass: "form-title" }, [
                  _c("h1", {
                    domProps: {
                      textContent: _vm._s(_vm.$t("register.form.title")),
                    },
                  }),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-group" }, [
                  _c(
                    "div",
                    {
                      staticClass: "input-form-group-stemdo",
                      attrs: { id: "inpunts" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "input-form-group-stemdo",
                          attrs: { id: "inpunts" },
                        },
                        [
                          _c("div", { staticClass: "name-input" }, [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  width: "37%",
                                  "min-width": "141px",
                                },
                              },
                              [
                                _c("label", {
                                  staticClass: "login-span",
                                  attrs: { for: "first_name" },
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$t("register.form.name")
                                    ),
                                  },
                                }),
                                _vm._v(" "),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.registerAccount.first_name,
                                      expression: "registerAccount.first_name",
                                    },
                                  ],
                                  staticClass: "input-form-stemdo",
                                  class: {
                                    "input-error":
                                      _vm.isFormInvalid &&
                                      !_vm.$v.registerAccount.first_name.$model,
                                  },
                                  attrs: {
                                    id: "first_name",
                                    type: "text",
                                    "data-cy": "nameRegister",
                                  },
                                  domProps: {
                                    value: _vm.registerAccount.first_name,
                                  },
                                  on: {
                                    change: _vm.checkNameAndSurname,
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        _vm.registerAccount,
                                        "first_name",
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  width: "63%",
                                  "min-width": "151px",
                                },
                              },
                              [
                                _c("label", {
                                  staticClass: "login-span",
                                  attrs: { for: "last_name" },
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$t("register.form.surname")
                                    ),
                                  },
                                }),
                                _vm._v(" "),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.registerAccount.last_name,
                                      expression: "registerAccount.last_name",
                                    },
                                  ],
                                  staticClass: "input-form-stemdo ml-2",
                                  class: {
                                    "input-error":
                                      _vm.isFormInvalid &&
                                      !_vm.$v.registerAccount.last_name.$model,
                                  },
                                  attrs: {
                                    id: "last_name",
                                    type: "text",
                                    "data-cy": "surnameRegister",
                                  },
                                  domProps: {
                                    value: _vm.registerAccount.last_name,
                                  },
                                  on: {
                                    change: _vm.checkNameAndSurname,
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        _vm.registerAccount,
                                        "last_name",
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                              ]
                            ),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "input-form-group-stemdo",
                          attrs: { id: "inpunts" },
                        },
                        [
                          _c("label", {
                            staticClass: "login-span",
                            attrs: { for: "email" },
                            domProps: {
                              textContent: _vm._s(
                                _vm.$t("register.form.email")
                              ),
                            },
                          }),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.$v.registerAccount.email.$model,
                                expression: "$v.registerAccount.email.$model",
                              },
                            ],
                            staticClass: "input-form-stemdo",
                            class: {
                              valid:
                                !_vm.$v.registerAccount.email.$invalid &&
                                _vm.$v.registerAccount.email.$dirty &&
                                _vm.$v.registerAccount.email.$model,
                              invalid:
                                _vm.$v.registerAccount.email.$invalid &&
                                _vm.$v.registerAccount.email.$dirty &&
                                _vm.$v.registerAccount.email.$model,
                              "input-error":
                                _vm.$v.registerAccount.email.$invalid &&
                                _vm.$v.registerAccount.email.$dirty &&
                                _vm.$v.registerAccount.email.$model,
                            },
                            attrs: {
                              id: "email",
                              type: "email",
                              name: "email",
                              inlength: "5",
                              maxlength: "254",
                              email: "",
                              required: "",
                              "data-cy": "email",
                              state: !_vm.$v.registerAccount.email.$invalid,
                            },
                            domProps: {
                              value: _vm.$v.registerAccount.email.$model,
                            },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.$v.registerAccount.email,
                                  "$model",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                          _vm._v(" "),
                          (_vm.$v.registerAccount.email.$invalid &&
                            _vm.$v.registerAccount.email.$model) ||
                          (_vm.isFormInvalid &&
                            !_vm.$v.registerAccount.email.$model)
                            ? _c("span", {
                                staticClass: "span-error",
                                domProps: {
                                  textContent: _vm._s(
                                    _vm.$t(
                                      "global.messages.validate.email.required"
                                    )
                                  ),
                                },
                              })
                            : _vm._e(),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "input-form-group-stemdo",
                          attrs: { id: "inpunts" },
                        },
                        [
                          _c("label", {
                            staticClass: "login-span",
                            attrs: { for: "phone" },
                            domProps: {
                              textContent: _vm._s(
                                _vm.$t("register.form.phone")
                              ),
                            },
                          }),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.$v.registerAccount.phone.$model,
                                expression: "$v.registerAccount.phone.$model",
                              },
                            ],
                            staticClass: "input-form-stemdo",
                            class: {
                              valid:
                                !_vm.$v.registerAccount.phone.$invalid &&
                                _vm.$v.registerAccount.phone.$dirty &&
                                _vm.$v.registerAccount.phone.$model,
                              invalid:
                                _vm.$v.registerAccount.phone.$invalid &&
                                _vm.$v.registerAccount.phone.$dirty &&
                                _vm.$v.registerAccount.phone.$model,
                              "input-error":
                                _vm.$v.registerAccount.phone.$invalid &&
                                _vm.$v.registerAccount.phone.$dirty &&
                                _vm.$v.registerAccount.phone.$model,
                            },
                            attrs: {
                              id: "phone",
                              type: "text",
                              "data-cy": "phoneRegister",
                            },
                            domProps: {
                              value: _vm.$v.registerAccount.phone.$model,
                            },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.$v.registerAccount.phone,
                                  "$model",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                          _vm._v(" "),
                          (_vm.$v.registerAccount.phone.$invalid &&
                            _vm.$v.registerAccount.phone.$model) ||
                          (_vm.isFormInvalid &&
                            !_vm.$v.registerAccount.phone.$model)
                            ? _c("span", {
                                staticClass: "span-error",
                                domProps: {
                                  textContent: _vm._s(
                                    _vm.$t(
                                      "register.messages.validate.phone.required"
                                    )
                                  ),
                                },
                              })
                            : _vm._e(),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "input-form-group-stemdo",
                          attrs: { id: "inpunts" },
                        },
                        [
                          _c("label", {
                            staticClass: "login-span",
                            attrs: { for: "department" },
                            domProps: {
                              textContent: _vm._s(
                                _vm.$t("clientDepartment.detail.role")
                              ),
                            },
                          }),
                          _vm._v(" "),
                          _c("v-select", {
                            staticClass:
                              "input-form-stemdo no-underline select-input custom-v-select",
                            class: {
                              "input-error":
                                _vm.isFormInvalid &&
                                !_vm.$v.registerAccount.department_id.$model,
                            },
                            staticStyle: { padding: "0.2rem 0rem" },
                            attrs: {
                              id: "department",
                              "data-cy": "department-select",
                              items: _vm.clientDepartments,
                              "item-text": (item) =>
                                _vm.$t(`clientDepartment.detail.${item.id}`),
                              "item-value": "id",
                              dense: "",
                              flat: "",
                              solo: "",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "item",
                                  fn: function ({ item }) {
                                    return [
                                      _c("div", {
                                        class: [
                                          "text--text",
                                          "text--base",
                                          "text-body-1",
                                        ],
                                        attrs: {
                                          "data-cy": "department-select-item",
                                        },
                                        domProps: {
                                          textContent: _vm._s(
                                            _vm.$t(
                                              `clientDepartment.detail.${item.id}`
                                            )
                                          ),
                                        },
                                      }),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              262149075
                            ),
                            model: {
                              value: _vm.registerAccount.department_id,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.registerAccount,
                                  "department_id",
                                  $$v
                                )
                              },
                              expression: "registerAccount.department_id",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "login-checkbox" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.termsAccepted,
                          expression: "termsAccepted",
                        },
                      ],
                      attrs: { type: "checkbox", id: "terms" },
                      domProps: {
                        checked: Array.isArray(_vm.termsAccepted)
                          ? _vm._i(_vm.termsAccepted, null) > -1
                          : _vm.termsAccepted,
                      },
                      on: {
                        change: function ($event) {
                          var $$a = _vm.termsAccepted,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 && (_vm.termsAccepted = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.termsAccepted = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.termsAccepted = $$c
                          }
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        class:
                          _vm.termsAccepted === null || _vm.termsAccepted
                            ? "terms-link-accepted"
                            : "terms-link-not-accepted",
                      },
                      [
                        _c("div", { staticClass: "terms" }, [
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.$t("register.messages.terms.part1")) +
                              "\n                "
                          ),
                          _c("a", { attrs: { href: "/", target: "_blank" } }, [
                            _vm._v(
                              _vm._s(_vm.$t("register.messages.terms.link1"))
                            ),
                          ]),
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.$t("register.messages.terms.part2")) +
                              "\n                "
                          ),
                          _c("a", { attrs: { href: "/", target: "_blank" } }, [
                            _vm._v(
                              _vm._s(_vm.$t("register.messages.terms.link2"))
                            ),
                          ]),
                          _vm._v(".\n              "),
                        ]),
                      ]
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("SButton", {
                  attrs: {
                    btnStyle: "primary",
                    sText: "register.form.button",
                    action: _vm.validateForm,
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.success
          ? _c(
              "div",
              {
                staticClass: "SForm d-flex flex-column align-items-center",
                attrs: {
                  id: "register-form",
                  name: "registerForm",
                  role: "form",
                  "no-validate": "",
                },
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                  },
                },
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "text-center form-title mb-0 d-flex align-items-center justify-content-center flex-column",
                  },
                  [
                    _c("h2", {
                      staticStyle: { "font-weight": "500 !important" },
                      domProps: {
                        textContent: _vm._s(
                          _vm.$t("register.messages.success.title")
                        ),
                      },
                    }),
                  ]
                ),
                _vm._v(" "),
                _c("div", [
                  _c("span", {
                    staticClass: "text-body-1-bold",
                    domProps: {
                      textContent: _vm._s(
                        _vm.$t("register.messages.success.question")
                      ),
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "d-flex align-items-start mb-6 mt-7" },
                    [
                      _vm._m(1),
                      _vm._v(" "),
                      _c("p", {
                        staticClass: "text-body-1 mb-0 ml-2",
                        domProps: {
                          textContent: _vm._s(
                            _vm.$t("register.messages.success.step1")
                          ),
                        },
                      }),
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "d-flex align-items-start mb-6" }, [
                    _vm._m(2),
                    _vm._v(" "),
                    _c("p", {
                      staticClass: "text-body-1 mb-0 ml-2",
                      domProps: {
                        textContent: _vm._s(
                          _vm.$t("register.messages.success.step2")
                        ),
                      },
                    }),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "d-flex align-items-start mb-6" }, [
                    _c(
                      "div",
                      { staticClass: "circle-step-green mt-1 mr-2" },
                      [
                        _c("v-img", {
                          staticStyle: { width: "16px", height: "16px" },
                          attrs: { src: "/content/svgs/checkmark-outline.svg" },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("p", {
                      staticClass: "text-body-1 mb-0 ml-2",
                      domProps: {
                        textContent: _vm._s(
                          _vm.$t("register.messages.success.step3")
                        ),
                      },
                    }),
                  ]),
                ]),
              ]
            )
          : _vm._e(),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "image-container" }, [
      _c("img", {
        attrs: {
          src: require("@content/images/deloitte-logo.png"),
          alt: "company Logo",
        },
      }),
      _vm._v(" "),
      _c("img", {
        attrs: {
          src: require("@content/images/ferrovial-logo.png"),
          alt: "company Logo",
        },
      }),
      _vm._v(" "),
      _c("img", {
        attrs: {
          src: require("@content/images/bbva-logo.png"),
          alt: "company Logo",
        },
      }),
      _vm._v(" "),
      _c("img", {
        attrs: {
          src: require("@content/images/santander-logo.png"),
          alt: "company Logo",
        },
      }),
      _vm._v(" "),
      _c("img", {
        attrs: {
          src: require("@content/images/radisson-logo.png"),
          alt: "company Logo",
        },
      }),
      _vm._v(" "),
      _c("img", {
        attrs: {
          src: require("@content/images/andbank-logo.png"),
          alt: "company Logo",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "circle-step mt-1 mr-2" }, [
      _c("span", { staticClass: "text-subtitle-4" }, [_vm._v("1")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "circle-step mt-1 mr-2" }, [
      _c("span", { staticClass: "text-subtitle-4" }, [_vm._v("2")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }