import { useStore } from '@/plugins/vuex';
import StemdoerService from '@/sections/admin/entities/stemdoer/stemdoer.service';
import { Stemdoer, Stemdoers } from '@/shared/model/stemdoer.model';
import { computed, defineComponent, inject, onMounted, ref, watch } from 'vue';
import StemdoersList from './stemdoers-list/StemdoersList.vue';
import StemdoersFilter from './stemdoers-filter/StemdoersFilter.vue';
import SelectionMultipleFilter from '@/shared/model/filter/single/selection-multiple-filter.model';
import Filters from '@/shared/model/filter/filters.model';
import TextFilter from '@/shared/model/filter/single/text-filter.model';
import FilterGroupOr from '@/shared/model/filter/group/filter-group-or.model';

export default defineComponent({
  components: {
    StemdoersList,
    StemdoersFilter
  },
  setup() {
    const store = useStore();
    const stemdoerService: StemdoerService = inject('stemdoerService');
    const stemdoers = ref<Stemdoers>(Stemdoers.empty());
    const filteredStemdoers = ref<Stemdoers>(Stemdoers.empty());
    const isLoading = ref(true);
    const displaySkeleton = computed(() => {
      return isLoading.value || (stemdoers.value.length === 0 && isLoading.value);
    });
    const filters = ref<Filters>(new Filters('AND'));

    onMounted(() => {
      retrieveAllStemdoersByManager().then(() => {
        filteredStemdoers.value = filters.value.filter(stemdoers.value) as Stemdoers;
      });
    });

    watch(
      () => filters.value,
      f => {
        filteredStemdoers.value = f.filter(stemdoers.value) as Stemdoers;
      },
      { deep: true }
    );

    const retrieveAllStemdoersByManager = () => {
      isLoading.value = true;
      return stemdoerService
        .retrieveStemdoersByDate(new Date())
        .then(res => {
          stemdoers.value = Stemdoers.from(res.data);
          filteredStemdoers.value = Stemdoers.from(res.data);
          isLoading.value = false;
        })
        .catch(err => {
          console.error(err);
        });
    };

    const handleVerticalChange = verticalIds => {
      filters.value.add(new SelectionMultipleFilter('verticals', verticalIds));
      store.commit('pagesStore/manager/stemdoers/setVerticalFilter', verticalIds);
    };

    const handleStatusChange = statusIds => {
      filters.value.add(new SelectionMultipleFilter('hide', statusIds));
      store.commit('pagesStore/manager/stemdoers/setStatusFilter', statusIds);
    };

    const handleSearchChange = searchTerm => {
      const groupOr = new FilterGroupOr('searchText');
      groupOr.add(new TextFilter('verticals', searchTerm));
      groupOr.add(new TextFilter('name', searchTerm));
      groupOr.add(new TextFilter('surname', searchTerm));
      groupOr.add(new TextFilter('email', searchTerm));
      groupOr.add(new TextFilter('position', searchTerm));
      filters.value.add(groupOr);
      store.commit('pagesStore/manager/stemdoers/setSearchFilter', searchTerm);
    };

    return {
      filteredStemdoers,
      stemdoers,
      displaySkeleton,
      handleVerticalChange,
      handleStatusChange,
      handleSearchChange
    };
  }
});
