import { defineComponent, ref, PropType, onMounted, watch, computed } from 'vue';

export interface ISelectState {
  id?: string;
  desc?: string;
}

export default defineComponent({
  props: {
    defaultSelectedItemsIds: {
      type: Array as PropType<string[]>,
      required: false
    },
    items: {
      type: Array as PropType<ISelectState[]>,
      required: true
    },
    placeholder: {
      type: String,
      required: true
    },
    onChange: {
      type: Function,
      required: true
    }
  },
  setup(props) {
    const selectedItems = ref([]);
    const labelDisplay = ref('');
    const isAllSelected = ref(false);
    const uniqueItems = computed(() => {
      return Array.from(new Set(props.items.map(item => item.id))).map(id => props.items.find(item => item.id === id));
    });

    watch(
      () => props.defaultSelectedItemsIds,
      (defaultSelectedItemsIds: any) => {
        const newSelectedItems = defaultSelectedItemsIds.map((item: string) => ({ id: item, desc: null }));
        handleChange(newSelectedItems);
      }
    );

    const handleChange = (newSelectedItems: any) => {
      const updatedSelectedItems = getUpdatedSelectedItems(newSelectedItems);
      selectedItems.value = updatedSelectedItems;
      isAllSelected.value = updatedSelectedItems.length === uniqueItems.value.length - 1;
      props.onChange(updatedSelectedItems.map((item: any) => item.id));
    };

    const getUpdatedSelectedItems = (newSelectedItems: any) => {
      const newSelectedItemsIds = newSelectedItems.map((item: any) => item.id);
      const isViewAllSelected = newSelectedItemsIds.includes('VIEW_ALL');
      const areAllItemsSelected = newSelectedItemsIds.length === uniqueItems.value.length;

      if (isViewAllSelected) {
        if (areAllItemsSelected) {
          return [];
        } else {
          return uniqueItems.value.filter((item: any) => item.id !== 'VIEW_ALL');
        }
      } else {
        if (areAllItemsSelected) {
          return uniqueItems.value.filter((item: any) => !newSelectedItemsIds.includes(item.id));
        } else {
          return newSelectedItems;
        }
      }
    };

    const isItemSelected = (item: any) => {
      return selectedItems.value.some((selectedItem: any) => selectedItem.id === item.id);
    };

    const setLabelDisplay = () => {
      if (selectedItems.value.length === 0) {
        labelDisplay.value = props.placeholder.toString();
        return;
      }
      labelDisplay.value = `${props.placeholder.toString()} · ${selectedItems.value.length}`;
    };

    const handleFocus = () => {
      labelDisplay.value = '';
    };

    const handleBlur = () => {
      setLabelDisplay();
    };

    watch(selectedItems, () => {
      setLabelDisplay();
    });

    return {
      selectedItems,
      labelDisplay,
      isAllSelected,
      handleChange,
      handleFocus,
      isItemSelected,
      handleBlur
    };
  }
});
