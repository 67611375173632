import { computed, defineComponent, inject, onMounted, ref } from 'vue';
import CandidatesList from '@/sections/client/candidates/candidates-list/CandidatesList.vue';
import CandidatesFilter from '@/sections/client/candidates/candidates-filter/CandidatesFilter.vue';
import StemdoerService from '@/sections/admin/entities/stemdoer/stemdoer.service';
import { IStemdoer } from '@/shared/model/stemdoer.model';
import { useStore } from '@/plugins/vuex';
import NoContent from './no-content/noContent.vue';

export default defineComponent({
  components: {
    CandidatesList,
    CandidatesFilter,
    NoContent
  },
  setup() {
    const store = useStore();
    const stemdoerService: StemdoerService = inject('stemdoerService');
    const candidates = ref<IStemdoer[]>([]);
    const isLoading = ref(true);
    const isCandidateListEmpty = ref(true);
    const search = ref('');

    onMounted(() => {
      retrieveCandidates();
    });

    const retrieveCandidates = async () => {
      const loggedClientId = store.getters['client'].id;
      const currentDate = new Date();
      candidates.value = await stemdoerService.retrieveByClientTeams(loggedClientId, currentDate, true);
      isCandidateListEmpty.value = candidates.value.length == 0;
      isLoading.value = false;
    };

    const filteredCandidates = computed(() => {
      if (!search.value) return candidates.value;
      const searchValue = search.value.toLowerCase().trim();
      const isRateSearch = /^\d+€?$/.test(searchValue);
      return candidates.value.filter(candidate => {
        const fullName = `${candidate.name} ${candidate.surname}`.toLowerCase();
        const position = candidate.position ? candidate.position.toLowerCase() : '';
        const rate =
          candidate.stemdoerTeams.length > 0 && candidate.stemdoerTeams[0].rate ? candidate.stemdoerTeams[0].rate.toString() : '';
        if (isRateSearch) {
          const normalizedRate = rate.replace(' €', '').trim();
          return normalizedRate.includes(searchValue.replace(' €', '').trim());
        }
        return fullName.includes(searchValue) || position.includes(searchValue) || rate.includes(searchValue);
      });
    });

    return {
      candidates,
      isLoading,
      isCandidateListEmpty,
      search,
      filteredCandidates
    };
  }
});
