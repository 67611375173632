var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    {
      staticClass: "mb-8",
      attrs: { id: "no-content", fluid: "", "data-cy": "no-content-container" },
    },
    [
      _c("v-col", { attrs: { cols: "12" } }, [
        _c(
          "div",
          { staticClass: "menu", attrs: { "data-cy": "no-content-menu" } },
          [
            _c("v-row", [
              _c("strong", {
                attrs: { "data-cy": "no-content-title" },
                domProps: {
                  textContent: _vm._s(_vm.$t("no-content.title-text")),
                },
              }),
            ]),
            _vm._v(" "),
            _c("v-row", [
              _c("p", {
                staticClass: "mb-3 pl-2",
                staticStyle: { width: "55%", margin: "auto" },
                attrs: { "data-cy": "no-content-description" },
                domProps: {
                  textContent: _vm._s(_vm.$t("no-content.description")),
                },
              }),
            ]),
            _vm._v(" "),
            _c(
              "v-row",
              [
                _c("s-button", {
                  staticStyle: {
                    background:
                      "linear-gradient(75deg, #4237ff 0%, #9747ff 100%)",
                  },
                  attrs: {
                    "data-cy": "no-content-button",
                    sText: "no-content.button-text",
                    action: _vm.redirectToExplore,
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticStyle: {
              position: "relative",
              width: "100%",
              height: "100%",
            },
            attrs: { "data-cy": "no-content-graphics" },
          },
          [
            _c("div", {
              staticStyle: {
                position: "absolute",
                top: "0",
                left: "0",
                width: "100%",
                height: "100%",
                background:
                  "linear-gradient(0deg, rgba(255, 255, 255, 1) 16.5%, rgba(0, 194, 177, 0) 100%)",
                "z-index": "1",
              },
              attrs: { "data-cy": "no-content-gradient" },
            }),
            _vm._v(" "),
            _vm._l(_vm.greyBars, function (value, index) {
              return _c("v-sheet", {
                key: index,
                staticClass: "mb-1",
                staticStyle: { "z-index": "0" },
                attrs: {
                  height: "60px",
                  width: "100%",
                  rounded: "",
                  color: "#F9F9F9",
                  "data-cy": "no-content-grey-bar",
                },
              })
            }),
          ],
          2
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }