var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "custom-v-search" },
    [
      _c("v-text-field", {
        staticClass: "custom-text-field text-body-1",
        attrs: {
          density: "compact",
          label: _vm.placeholder,
          "prepend-inner-icon": "mdi-magnify",
          variant: "solo-filled",
          flat: "",
          solo: "",
          dense: "",
          "hide-details": "",
          "data-cy": "s-search",
        },
        on: { input: _vm.handleChange },
        model: {
          value: _vm.searchText,
          callback: function ($$v) {
            _vm.searchText = $$v
          },
          expression: "searchText",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }