import { computed, defineComponent, onMounted, ref, watch } from 'vue';
import { useStore } from '@/plugins/vuex';
import { useI18N } from '@/plugins/i18n';
import vuetify from '@/plugins/vuetify';
import Filters, { FilterFields } from '@/sections/shared/explore/model/Filters';

export interface IAtocompleteMultipleState {
  id: string;
  desc: string;
}

export default defineComponent({
  props: {
    items: {
      type: Array as () => IAtocompleteMultipleState[],
      required: true
    },
    placeholder: {
      type: String,
      default: ''
    },
    action: {
      type: Function,
      required: true
    },
    inputHeight: {
      type: Number,
      required: false
    },
    filterField: String,
    disabled: {
      type: Boolean,
      default: false,
      required: false
    },
    labelDisplaySelection: {
      type: Boolean,
      default: false
    },
    label: {
      type: String
    },
    tempFilters: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  setup(props) {
    const store = useStore();
    const i18n = useI18N();
    const selectedItems = ref([]);
    const labelDisplay = ref('');
    const customStyle = computed(() => {
      const themeColors: any = vuetify.framework.theme.currentTheme;
      return props.tempFilters === true ? { '--custom-color': themeColors.text.lighten2 } : { '--custom-color': themeColors.text.lighten1 };
    });

    onMounted(() => {
      if (props.filterField) {
        selectedItems.value = getComponentState();
      }
      setLabelDisplay();
    });

    if (!props.tempFilters) {
      watch(
        () => store.getters['filterStore/filters'],
        (filters: Filters) => {
          selectedItems.value = getComponentState();
          setLabelDisplay();
        },
        { deep: true }
      );
    }

    if (props.tempFilters) {
      watch(
        () => store.getters['filterStore/tempFilters'],
        (filters: Filters) => {
          selectedItems.value = getComponentState();
          setLabelDisplay();
        },
        { deep: true }
      );
    }

    const getComponentState = () => {
      const payload = props.filterField as FilterFields;
      if (!props.tempFilters) {
        return store.getters['filterStore/getAutoCompleteMultipleState'](payload);
      }
      return store.getters['filterStore/getAutoCompleteMultipleTempState'](payload);
    };

    const handleChange = (newSelectedItems: IAtocompleteMultipleState[]) => {
      const pNewSelectedItems = JSON.parse(JSON.stringify(newSelectedItems));
      props.action(props.filterField, pNewSelectedItems);
    };

    const isItemSelected = item => {
      return selectedItems.value.find(i => i.id === item.id);
    };

    const handleBlur = () => {
      setLabelDisplay();
    };

    const setLabelDisplay = () => {
      if (!props.labelDisplaySelection) {
        labelDisplay.value = i18n.t(props.placeholder).toString();
        return;
      }

      if (selectedItems.value.length === 1) {
        labelDisplay.value = `${i18n.t(selectedItems.value[0].desc).toString()}`;
        return;
      }
      labelDisplay.value = `${i18n.t(props.label).toString()} · ${selectedItems.value.length}`;
    };

    const handleFocus = () => {
      labelDisplay.value = '';
    };

    return { customStyle, selectedItems, labelDisplay, handleChange, isItemSelected, handleFocus, handleBlur };
  }
});
