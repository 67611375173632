var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { attrs: { id: "teamStemdoers", "data-cy": "checkout-page" } },
    [
      this.loaded
        ? _c(
            "div",
            {
              staticClass: "checkout",
              attrs: { "data-cy": "checkout-loaded-section" },
            },
            [
              _c(
                "div",
                {
                  staticClass: "toolBoxTop",
                  attrs: { "data-cy": "checkout-header" },
                },
                [
                  _c(
                    "div",
                    [
                      _c("SButtonBack", {
                        attrs: { "data-cy": "checkout-back-btn" },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", [
                    _c("span", { attrs: { "data-cy": "checkout-title" } }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("label.teams.selected.myTeam")) +
                          "\n        "
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "d-flex justify-content-end align-items-end",
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "btnPDF",
                          staticStyle: {
                            "background-color":
                              "rgba(255, 255, 255, 0) !important",
                          },
                          attrs: {
                            loading: _vm.loading,
                            disabled: _vm.loading,
                            fab: "",
                            "data-cy": "checkout-pdf-btn",
                          },
                          on: {
                            click: function ($event) {
                              _vm.loading = true
                              _vm.startLoading()
                            },
                          },
                        },
                        [
                          _c("v-img", {
                            staticClass: "iconPDF",
                            attrs: {
                              src: "/content/svgs/pdf-button.svg",
                              "data-cy": "checkout-pdf-icon",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticClass: "ml-1",
                          attrs: { "data-cy": "checkout-pdf-text" },
                        },
                        [_vm._v("PDF")]
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "checkout-container",
                  attrs: { "data-cy": "checkout-container" },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "checkout-view",
                      attrs: { "data-cy": "checkout-view" },
                    },
                    [
                      _c("SPopUp", {
                        staticClass: "checkoutError",
                        attrs: {
                          isOpen: _vm.errorPopUp,
                          sText: _vm.$t("label.teams.selected.errorPopUp"),
                          isValid: !_vm.errorPopUp,
                          "data-cy": "checkout-error-popup",
                        },
                        on: { close: _vm.closePopUp },
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "checkout-team",
                          attrs: { "data-cy": "checkout-team-list" },
                        },
                        _vm._l(_vm.arrayStemdoers, function (stemdoer) {
                          return _c(
                            "div",
                            {
                              key: stemdoer.id,
                              attrs: {
                                "no-gutters": "",
                                "data-cy": "checkout-stemdoer-item",
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "pa-0 mb-4" },
                                [
                                  _c("SCardStemdoer", {
                                    attrs: {
                                      "data-cy": "checkout-stemdoer-card",
                                      stemdoer: stemdoer,
                                      rate: stemdoer.rate,
                                      cardOptions: _vm.stemdoerCardOptions,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          )
                        }),
                        0
                      ),
                      _vm._v(" "),
                      _vm.isClient && _vm.totalHourlyCost > 0
                        ? _c(
                            "div",
                            {
                              staticClass: "checkout-info",
                              attrs: { "data-cy": "checkout-info" },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "box w-100 mb-4",
                                  attrs: {
                                    "data-cy": "checkout-hourly-cost-box",
                                  },
                                },
                                [
                                  _c("span", {
                                    staticClass:
                                      "text-body-2-medium text--text text--lighten-1",
                                    attrs: {
                                      "data-cy": "checkout-hourly-cost-label",
                                    },
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.$t(
                                          "label.teams.selected.teamCostHour"
                                        )
                                      ),
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "text-h2-bold text--text text--base",
                                      attrs: {
                                        "data-cy": "checkout-hourly-cost-value",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(
                                            _vm.$n(
                                              _vm.totalHourlyCost /
                                                _vm.arrayStemdoers.length,
                                              "decimal"
                                            ) + " €"
                                          ) +
                                          "/h\n            "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "checkout-totalCost w-100",
                                  attrs: {
                                    "data-cy": "checkout-daily-monthly-costs",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "totalCost-mini",
                                      staticStyle: { "margin-right": "8px" },
                                      attrs: {
                                        "data-cy": "checkout-daily-cost-box",
                                      },
                                    },
                                    [
                                      _c("span", {
                                        staticClass:
                                          "text-body-2-medium text--text text--lighten-1",
                                        domProps: {
                                          textContent: _vm._s(
                                            _vm.$t(
                                              "label.teams.selected.teamCostDay"
                                            )
                                          ),
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "h4",
                                        {
                                          attrs: {
                                            "data-cy":
                                              "checkout-daily-cost-value",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(
                                                _vm.$n(
                                                  _vm.totalDailyCost,
                                                  "decimal"
                                                ) + " €"
                                              ) +
                                              "\n              "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "totalCost-mini",
                                      staticStyle: { "margin-left": "8px" },
                                      attrs: {
                                        "data-cy": "checkout-monthly-cost-box",
                                      },
                                    },
                                    [
                                      _c("span", {
                                        staticClass:
                                          "text-body-2-medium text--text text--lighten-1",
                                        domProps: {
                                          textContent: _vm._s(
                                            _vm.$t(
                                              "label.teams.selected.teamCostMonth"
                                            )
                                          ),
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "h4",
                                        {
                                          attrs: {
                                            "data-cy":
                                              "checkout-monthly-cost-value",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(
                                                _vm.$n(
                                                  _vm.totalDailyCost * 20,
                                                  "decimal"
                                                ) + " €"
                                              ) +
                                              "\n              "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _vm.teamRequestStatus === null ||
                              _vm.teamRequestStatus === _vm.RequestStatus.SAVED
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "checkout-buttons w-100",
                                      attrs: {
                                        "data-cy": "checkout-buttons-saved",
                                      },
                                    },
                                    [
                                      _c("SButton", {
                                        attrs: {
                                          "data-cy": "checkout-request-btn",
                                          btnStyle: "primary",
                                          sText: "entity.action.request",
                                          action: _vm.openBlockTeamModal,
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("SButton", {
                                        staticStyle: { "margin-top": "16px" },
                                        attrs: {
                                          "data-cy":
                                            "checkout-save-proposal-btn",
                                          btnStyle: "secondary",
                                          sText:
                                            "label.teams.selected.saveProposal",
                                          action: _vm.openProposalTeamModal,
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm.teamRequestStatus ===
                                  _vm.RequestStatus.VALIDATED
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "checkout-buttons",
                                      attrs: {
                                        "data-cy": "checkout-buttons-validated",
                                      },
                                    },
                                    [
                                      _c("SButton", {
                                        attrs: {
                                          "data-cy": "checkout-approve-btn",
                                          btnStyle: "primary",
                                          sText:
                                            "requests.info-box.pending.accept",
                                          action: _vm.approveTeamRequest,
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("SButton", {
                                        staticStyle: { "margin-top": "16px" },
                                        attrs: {
                                          "data-cy": "checkout-send-msg-btn",
                                          btnStyle: "secondary",
                                          sText:
                                            "requests.info-box.pending.send-msg",
                                          action: () => {},
                                          disabled: true,
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "checkout-chart",
                                  attrs: {
                                    "data-cy": "checkout-chart-section",
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "title-span",
                                      attrs: {
                                        "data-cy": "checkout-chart-title",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(
                                            _vm.$t(
                                              "label.teams.selected.averageSkill"
                                            )
                                          ) +
                                          "\n            "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  this.loaded
                                    ? _c(
                                        "div",
                                        {
                                          ref: "chartSelected",
                                          staticClass: "chart-container",
                                          staticStyle: {
                                            position: "relative",
                                            width: "100%",
                                            height: "100%",
                                          },
                                          attrs: {
                                            "data-cy":
                                              "checkout-chart-container",
                                          },
                                        },
                                        [
                                          _c("SRadarStemdoersTechs", {
                                            attrs: {
                                              "data-cy":
                                                "checkout-radar-stemdoers",
                                              stemdoerList: _vm.arrayStemdoers,
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "w-100" },
                                [
                                  _c("SFootprint", {
                                    attrs: { stemdoers: _vm.arrayStemdoers },
                                  }),
                                ],
                                1
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          ref: "modalVertical",
          attrs: {
            size: "lg",
            id: "user-details",
            "hide-footer": "",
            lazy: "",
            "data-cy": "checkout-user-details-modal",
          },
          on: {
            show: function ($event) {
              return _vm.onModalShow()
            },
          },
          scopedSlots: _vm._u([
            {
              key: "modal-title",
              fn: function () {
                return [
                  _c(
                    "span",
                    {
                      attrs: {
                        "data-cy": "checkout-user-details-title",
                        id: "user-detail",
                      },
                    },
                    [
                      _c("div", { staticClass: "centerPDF" }, [
                        _c("img", {
                          staticClass: "minilogo",
                          attrs: {
                            src: "/content/images/emini.png",
                            alt: "",
                            "data-cy": "checkout-user-details-logo",
                          },
                        }),
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.nameSelected) +
                            "\n          "
                        ),
                        _c(
                          "button",
                          {
                            staticClass: "btnPDF",
                            attrs: {
                              "data-cy": "checkout-user-details-pdf-btn",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.generatePDF()
                              },
                            },
                          },
                          [
                            _c("font-awesome-icon", {
                              attrs: { icon: "fa-solid fa-file-pdf" },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _vm._v(" "),
          _c("show-detail", {
            attrs: {
              "data-cy": "checkout-user-details-content",
              people: this.people,
              peopleImg: this.peopleImg,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("SModalTeamRequestConfirmation", {
        attrs: {
          "data-cy": "checkout-team-request-confirmation-modal",
          teamRequest: _vm.teamRequest,
          isOpen: _vm.isOpenBlockTeamModal,
          onClose: _vm.closeBlockTeamModal,
          action: _vm.onClickFormModal,
          customProps: _vm.customPropsModal,
        },
      }),
      _vm._v(" "),
      _c("SModalStemdoerNotAvailable", {
        attrs: {
          "data-cy": "checkout-stemdoer-not-available-modal",
          stemdoers: _vm.arrayStemdoersNotAvailable,
          isOpen: _vm.stemdoersNotAvailable,
          onClose: _vm.closeStemdoersNotAvailableModal,
        },
      }),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          ref: "finishBlock",
          staticStyle: {
            width: "100%",
            height: "100%",
            background: "white",
            "box-shadow": "0px 2px 8px #dbdbe0",
            "border-radius": "4px",
            padding: "20px",
            "text-align": "center",
          },
          attrs: {
            id: "finishBlock",
            "no-close-on-backdrop": "",
            "data-cy": "checkout-finish-block-modal",
          },
          on: {
            hide: function ($event) {
              return _vm.closeDialog()
            },
            show: function ($event) {
              return _vm.onModalShow()
            },
          },
        },
        [
          _c("span", {
            attrs: { slot: "modal-title" },
            domProps: { textContent: _vm._s(_vm.modalTitle) },
            slot: "modal-title",
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticStyle: { padding: "16px", "margin-top": "-16px" } },
            [
              _c("span", {
                staticClass: "modal-finish",
                staticStyle: { display: "block", "margin-bottom": "22px" },
                attrs: { "data-cy": "checkout-finish-block-modal-text" },
                domProps: { textContent: _vm._s(_vm.modalFinish) },
              }),
              _vm._v(" "),
              _c("SButton", {
                staticStyle: {
                  "font-size": "16px !important",
                  "font-weight": "700 !important",
                },
                attrs: {
                  btnStyle: "primary",
                  sText: "label.teams.selected.understood",
                  action: () => {
                    _vm.closeDialog()
                  },
                  "data-cy": "checkout-finish-block-modal-close-btn",
                },
              }),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          class: { "bg-white": true, "modal-open": _vm.dialog },
          attrs: {
            "max-width": "70%",
            "data-cy": "checkout-stemdoer-detail-dialog",
          },
          on: { input: () => _vm.resetOverflow() },
          model: {
            value: _vm.dialog,
            callback: function ($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog",
          },
        },
        [
          _vm.dialog
            ? _c("StemdoerDetail", {
                attrs: {
                  "data-cy": "checkout-stemdoer-detail-component",
                  stemdoer: _vm.stemdoerSelected,
                  closeDialog: () => {
                    _vm.closeDetailDialog()
                  },
                  rates: _vm.rate,
                  onAddToCart: () => {},
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }