var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "checkout-footprint",
      attrs: { "data-cy": "checkout-footprint-section" },
    },
    [
      _c(
        "span",
        {
          staticClass: "title-span",
          attrs: { "data-cy": "checkout-footprint-title" },
        },
        [
          _vm._v(
            "\n    " +
              _vm._s(_vm.$t("label.teams.selected.footprintLb")) +
              "\n  "
          ),
        ]
      ),
      _vm._v(" "),
      _c("div", { attrs: { "data-cy": "checkout-footprint-graphs" } }, [
        _c(
          "svg",
          {
            staticClass: "circle-container",
            attrs: {
              width: "134",
              height: "134",
              viewBox: "0 0 134 134",
              fill: "none",
              "data-cy": "checkout-footprint-graph-1",
            },
          },
          [
            _c(
              "g",
              { attrs: { transform: "scale(1,-1)" } },
              [
                _c("circle", {
                  attrs: { cx: "67", cy: "-67", r: "56", fill: "#F9F9F9" },
                }),
                _vm._v(" "),
                _c("circle", {
                  attrs: {
                    cx: "67",
                    cy: "-67",
                    r: "66",
                    stroke: "#DBDBE0",
                    "stroke-width": "2",
                    "stroke-linejoin": "round",
                    "stroke-dasharray": "2 2",
                  },
                }),
                _vm._v(" "),
                _c("circle", {
                  staticClass: "progress-ring__circle",
                  style: _vm.footprintCircle(_vm.footprint),
                  attrs: {
                    stroke: "url(#paint0_linear_447_20326)",
                    "stroke-width": "3",
                    "stroke-linecap": "round",
                    transform: "rotate(-90, 67, -67)",
                    fill: "transparent",
                    r: "66",
                    cx: "67",
                    cy: "-67",
                  },
                }),
                _vm._v(" "),
                _c(
                  "linearGradient",
                  {
                    attrs: {
                      id: "paint0_linear_447_20326",
                      x1: "10.6688",
                      y1: "-120.6",
                      x2: "132.842",
                      y2: "-112.519",
                      gradientUnits: "userSpaceOnUse",
                    },
                  },
                  [
                    _c("stop", { attrs: { "stop-color": "#4237FF" } }),
                    _vm._v(" "),
                    _c("stop", {
                      attrs: { offset: "1", "stop-color": "#9747FF" },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "foreignObject",
                  { attrs: { x: "0", y: "-134", width: "134", height: "134" } },
                  [
                    _c("div", { staticClass: "footprint-info" }, [
                      _c("div", { staticClass: "footprint-card" }, [
                        _c(
                          "span",
                          {
                            staticClass: "footprint-location",
                            attrs: {
                              "data-cy": "checkout-footprint-graph-1-location",
                            },
                          },
                          [_vm._v("Stemdo")]
                        ),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            staticClass: "footprint-value",
                            attrs: {
                              "data-cy": "checkout-footprint-graph-1-value",
                            },
                          },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(
                                  _vm.footprint == undefined ? 6 : _vm.footprint
                                ) +
                                "\n              "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("span", { staticClass: "footprint-tco" }, [
                          _vm._v(" tCO"),
                          _c("sub", [_vm._v("2")]),
                          _vm._v(
                            "eq/" +
                              _vm._s(
                                _vm.$t("label.teams.selected.footprintYear")
                              ) +
                              " "
                          ),
                        ]),
                      ]),
                    ]),
                  ]
                ),
              ],
              1
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "svg",
          {
            staticClass: "circle-container",
            attrs: {
              width: "134",
              height: "134",
              viewBox: "0 0 134 134",
              fill: "none",
              "data-cy": "checkout-footprint-graph-2",
            },
          },
          [
            _c(
              "g",
              { attrs: { transform: "scale(1,-1)" } },
              [
                _c("circle", {
                  attrs: { cx: "67", cy: "-67", r: "56", fill: "#F9F9F9" },
                }),
                _vm._v(" "),
                _c("circle", {
                  attrs: {
                    cx: "67",
                    cy: "-67",
                    r: "66",
                    stroke: "#DBDBE0",
                    "stroke-width": "2",
                    "stroke-linejoin": "round",
                    "stroke-dasharray": "2 2",
                  },
                }),
                _vm._v(" "),
                _c("circle", {
                  staticClass: "progress-ring__circle",
                  style: _vm.footprintCircle(_vm.stemdoers.length * 12),
                  attrs: {
                    stroke: "url(#paint0_linear_447_20326)",
                    "stroke-width": "3",
                    "stroke-linecap": "round",
                    transform: "rotate(-90, 67, -67)",
                    fill: "transparent",
                    r: "66",
                    cx: "67",
                    cy: "-67",
                  },
                }),
                _vm._v(" "),
                _c(
                  "linearGradient",
                  {
                    attrs: {
                      id: "paint0_linear_447_20326",
                      x1: "10.6688",
                      y1: "-120.6",
                      x2: "132.842",
                      y2: "-112.519",
                      gradientUnits: "userSpaceOnUse",
                    },
                  },
                  [
                    _c("stop", { attrs: { "stop-color": "#4237FF" } }),
                    _vm._v(" "),
                    _c("stop", {
                      attrs: { offset: "1", "stop-color": "#9747FF" },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "foreignObject",
                  { attrs: { x: "0", y: "-134", width: "134", height: "134" } },
                  [
                    _c("div", { staticClass: "footprint-info" }, [
                      _c("div", { staticClass: "footprint-card" }, [
                        _c(
                          "span",
                          {
                            staticClass: "footprint-location",
                            attrs: {
                              "data-cy": "checkout-footprint-graph-2-location",
                            },
                          },
                          [_vm._v("Madrid")]
                        ),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            staticClass: "footprint-value",
                            attrs: {
                              "data-cy": "checkout-footprint-graph-2-value",
                            },
                          },
                          [_vm._v(" ~ " + _vm._s(_vm.stemdoers.length * 12))]
                        ),
                        _vm._v(" "),
                        _c("span", { staticClass: "footprint-tco" }, [
                          _vm._v(" tCO"),
                          _c("sub", [_vm._v("2")]),
                          _vm._v(
                            "eq/" +
                              _vm._s(
                                _vm.$t("label.teams.selected.footprintYear")
                              ) +
                              " "
                          ),
                        ]),
                      ]),
                    ]),
                  ]
                ),
              ],
              1
            ),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }