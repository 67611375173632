var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    {
      ref: "requestListContainer",
      staticClass: "mx-0 mb-8 pl-0 pt-0",
      attrs: { id: "request-list", "data-cy": "team-requests-list-container" },
    },
    [
      _c("v-data-table", {
        staticClass: "remove-box-shadow custom-table",
        attrs: {
          "data-cy": "team-requests-table",
          headers: _vm.headers,
          items: _vm.teamRequestDisplayed,
          "no-data-text": _vm.noDataMsg(),
          "hide-default-footer": "",
          "disable-pagination": "",
          "disable-sort": "",
        },
        on: { "click:row": _vm.handleClickDetail },
        scopedSlots: _vm._u([
          {
            key: "item.stemdoers",
            fn: function ({ item }) {
              return [
                _c(
                  "div",
                  {
                    staticClass: "chip-container",
                    attrs: { "data-cy": "team-request-stemdoers" },
                  },
                  [
                    _vm._l(item.stemdoerRates, function (stemdoerRate, index) {
                      return [
                        index < 3
                          ? _c(
                              "v-chip",
                              {
                                key: "chip-rate-" + index,
                                staticClass: "overlapping-chip no-padding",
                                attrs: {
                                  color: "transparent",
                                  "data-cy": "stemdoer-avatar-chip",
                                },
                              },
                              [
                                _c("SAvatarStemdoer", {
                                  staticClass: "avatar",
                                  attrs: {
                                    stemdoId: stemdoerRate.stemdoId,
                                    "data-cy": "stemdoer-avatar",
                                  },
                                }),
                              ],
                              1
                            )
                          : index === 3
                          ? _c(
                              "v-chip",
                              {
                                key: "chip-rate-" + index,
                                staticClass: "overlapping-chip no-padding",
                                staticStyle: {
                                  border: "1px solid white !important",
                                },
                                attrs: {
                                  "data-cy": "remaining-stemdoers-chip",
                                },
                              },
                              [
                                _vm._v(
                                  "\n            +" +
                                    _vm._s(item.stemdoerRates.length - 3) +
                                    "\n          "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]
                    }),
                  ],
                  2
                ),
              ]
            },
          },
          {
            key: "item.name",
            fn: function ({ item }) {
              return [
                _c(
                  "span",
                  {
                    staticClass: "name-cell",
                    attrs: { "data-cy": "team-request-name" },
                  },
                  [_vm._v(_vm._s(item.name))]
                ),
              ]
            },
          },
          {
            key: "item.createdAt",
            fn: function ({ item }) {
              return [
                _c("span", { attrs: { "data-cy": "team-request-date" } }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$d(Date.parse(item.createdAt), "twoDigit")) +
                      "\n      "
                  ),
                ]),
              ]
            },
          },
          {
            key: "item.hourlyRate",
            fn: function ({ item }) {
              return [
                _c(
                  "span",
                  { attrs: { "data-cy": "team-request-hourlyRate" } },
                  [
                    _vm.getStatusDetails(item.status).displayApproximate
                      ? _c("span", [_vm._v("≈")])
                      : _vm._e(),
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          " " +
                            _vm.$n(item.hourlyRate * 8 * 20, "decimal") +
                            " €"
                        ) +
                        "\n      "
                    ),
                  ]
                ),
              ]
            },
          },
          {
            key: "item.status",
            fn: function ({ item }) {
              return [
                _c(
                  "div",
                  {
                    staticClass: "status-container",
                    attrs: {
                      "data-cy": "team-request-status",
                      "data-status": item.status,
                    },
                  },
                  [
                    _c("span", {
                      staticClass: "text-body-3 p-1 pl-3 pr-3",
                      style: {
                        backgroundColor: _vm.getStatusDetails(item.status)
                          .background,
                        border: `1px solid ${
                          _vm.getStatusDetails(item.status).border
                        }`,
                        fontWeight: "600 !important",
                      },
                      domProps: {
                        textContent: _vm._s(
                          _vm.getStatusDetails(item.status).translation
                        ),
                      },
                    }),
                  ]
                ),
              ]
            },
          },
          {
            key: "item.action",
            fn: function ({ item }) {
              return [
                item.status != _vm.RequestStatus.ON_GOING &&
                item.status != _vm.RequestStatus.CANCELLED_BY_MANAGER
                  ? _c(
                      "v-list-item",
                      { attrs: { "data-cy": "team-request-action-container" } },
                      [
                        _c(
                          "v-list-item-title",
                          {
                            staticClass: "list-option text-body-1",
                            attrs: { "data-cy": "team-request-cancel-btn" },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.openCancelModal(item)
                              },
                            },
                          },
                          [_c("div", { staticClass: "delete-trash" })]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }