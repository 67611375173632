var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { attrs: { id: "s-button-card", "data-cy": "filter-cards-container" } },
    [
      _c(
        "div",
        {
          staticClass: "d-flex align-items-end",
          attrs: { "data-cy": "filter-cards-main-btns-row" },
        },
        _vm._l(_vm.filterCardComponents, function (filterCardComponent, index) {
          return _c(
            "v-btn",
            {
              key: filterCardComponent.val,
              class: {
                active: _vm.isFilterActive(filterCardComponent.val),
                "btn-main pl-5 pr-5": true,
                off: _vm.selectedFilterCard != filterCardComponent.val,
                "other-btn": true,
                "first-btn": index === 0,
                "last-btn": index === _vm.filterCardComponents.length - 1,
              },
              attrs: {
                elevation: "0",
                stacked: "",
                "data-cy": "filter-card-main-btn",
              },
              on: {
                click: function ($event) {
                  return _vm.selectFilterCard(filterCardComponent.val)
                },
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "d-flex align-items-center ml-1 mr-1",
                  attrs: {
                    id: filterCardComponent.val,
                    "data-cy": "filter-card-main-content",
                  },
                },
                [
                  _c(
                    "p",
                    {
                      staticClass: "text-body-2-bold mb-0 text-subtitle-5 pr-4",
                      attrs: { "data-cy": "filter\n          -card-main-text" },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(filterCardComponent.text) +
                          "\n        "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "h2",
                    {
                      staticClass: "m-0 text-subtitle-5 p-1 pl-2 pr-2",
                      style: {
                        backgroundColor: filterCardComponent.backgroundColor,
                      },
                      attrs: { "data-cy": "filter-card-main-count" },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(filterCardComponent.count) +
                          "\n        "
                      ),
                    ]
                  ),
                ]
              ),
            ]
          )
        }),
        1
      ),
      _vm._v(" "),
      _vm.selectedFilterCard === _vm.StatusFilter.OnGoing ||
      _vm.selectedFilterCard === _vm.StatusFilter.Pending ||
      _vm.selectedFilterCard === _vm.StatusFilter.Validated
        ? _c(
            "div",
            {
              staticClass: "auxiliary-buttons",
              attrs: { "data-cy": "filter-cards-sub-btns-row" },
            },
            _vm._l(
              _vm.subFilterCardComponents,
              function (subFilterCardComponent) {
                return _c("v-btn", {
                  key: subFilterCardComponent.val,
                  class: [
                    "aux-btn ml-5 pl-2",
                    {
                      "selected-aux-btn": _vm.isSubFilterActive(
                        subFilterCardComponent.val
                      ),
                    },
                  ],
                  attrs: { outlined: "", "data-cy": "filter-card-sub-btn" },
                  domProps: {
                    textContent: _vm._s(
                      subFilterCardComponent.text +
                        " (" +
                        subFilterCardComponent.count +
                        ")"
                    ),
                  },
                  on: {
                    click: function ($event) {
                      return _vm.selectSubFilterCard(subFilterCardComponent.val)
                    },
                  },
                })
              }
            ),
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }